import React, { SetStateAction } from 'react';

import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import { EnumRewardAdjustmentStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

interface IProps {
  value: any;
  onChange: (e: any) => void;
  onClearSelect: () => void;
}

const PaRebateAdjustmentRequestStatusSingleSelection = (props: IProps) => {
  const { value, onChange, onClearSelect } = props;

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={tc('phSelection', { fieldName: tc('status') })}
      value={value}
      onChange={onChange}
      clearSelect={onClearSelect}
      enumData={EnumRewardAdjustmentStatus}
      //   nameFn={(item) => item}
    />
  );
};

export default PaRebateAdjustmentRequestStatusSingleSelection;
