export enum TableColumnKey {
  Operations = 'operations',
  ProgramAgentId = 'programAgentId',
  ApprovalProgress = 'approvalProgress',
  Amount = 'amount',
  Status = 'status',
  Remarks = 'remarks',
  CreatedBy = 'createdBy',
  CreationTime = 'creationTime',
  LastModifiedTime = 'lastModifiedTime',
}
