import { MpTextFieldNumberOnly } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
}

const LowerBoundAmountTextField = (props: IProps) => {
  const { label, value, onChange } = props;
  const { tc } = useTranslation();
  return (
    <MpTextFieldNumberOnly
      label={label || tc('phInputField', { fieldName: tc('lowerBoundAmount') })}
      value={value}
      onChange={onChange}
      decimalCount={16}
    />
  )
}

export default LowerBoundAmountTextField