import { EnumQuestionType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master";
import { SingleSelection } from "../../../../../../components";
import { useTranslation } from '../../../../../../hooks';

interface IProps {
    label?: string;
    value: any;
    onChange: (e: any) => void;
    revertEnum?: boolean;
}

const QuestionTypeSingleSelection = (props: IProps) => {
    const { label, value, onChange } = props;
    const { tqt } = useTranslation('enumConstants');

    const convertedQuestionTypeEnum = {
        [tqt(EnumQuestionType[Number(EnumQuestionType.Retail)])]: EnumQuestionType.Retail,
        [tqt(EnumQuestionType[Number(EnumQuestionType.Agent)])]: EnumQuestionType.Agent,
    }

    return (
        <SingleSelection
            label={label || ''}
            value={isNaN(Number(value)) ? '' : value}
            onChange={onChange}
            clearSelect={() => { }}
            enumData={convertedQuestionTypeEnum}
            nameFn={(name) => name}
            isNoSorting
        />
    )
}

export default QuestionTypeSingleSelection;

