import '../../../src/App.module.scss';

import { useState } from 'react';
import { MenuItemLink, useTranslate } from 'react-admin';

import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDownOutlined';
import { Box, Typography } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';

import { usePermission } from '../../hooks';
import { routeList } from '../../hooks/useRoutePermission';
import { COLOR_THEME } from '../../style/colorTheme';
import { hexToRGB, importantStyle } from '../../utils';
import { versionNumber } from '../../utils/constant';
import { useZusListStore } from '../../zustand/store';
import PMPLogo from '../PMPLogo';

const sx = {
  logo: {
    padding: '48px 24px',
  },
  menuPadding: {
    paddingLeft: '10px',
  },
  expanded: {
    color: COLOR_THEME.Menu.Expanded.Text,
    boxShadow: 'none',
    background: COLOR_THEME.Menu.Background,
  },
  notExpanded: {
    color: COLOR_THEME.Menu.Collapsed.Text,
    boxShadow: 'none',
    background: COLOR_THEME.Menu.Background,
  },
  muiAccordion: {
    '&.MuiAccordion-root:before': { content: 'none' },
    '& .MuiAccordionSummary-expandIconWrapper': {
      transform: 'rotate(270deg)',
      '>svg': { color: importantStyle(COLOR_THEME.Menu.Collapsed.Text) },
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(360deg)',
      '>svg': { color: importantStyle(COLOR_THEME.Menu.Expanded.Text) },
    },
    '& .MuiAccordionSummary-root.Mui-expanded': {
      maxHeight: '30px',
      margin: '5px 0px',
    },
    minWidth: '250px;',
  },
  accordionSummary: {
    '&.Mui-expanded': {
      minHeight: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
      margin: '0px',
    },
  },
  accordionDetails: {
    padding: '5px',
    a: {
      color: `rgba(${hexToRGB(COLOR_THEME.Menu.Collapsed.Text)}, 0.7)`,
      '&.RaMenuItemLink-active': {
        color: COLOR_THEME.Menu.Expanded.Text,
      },
    },
    'div:hover': {
      a: {
        backgroundColor: importantStyle(COLOR_THEME.Menu.HoverItem),
        color: COLOR_THEME.Menu.Collapsed.Text,
        '&.RaMenuItemLink-active': {
          color: COLOR_THEME.Menu.Expanded.Text,
        },
      },
    },
  },
};

export default function Menu(props: any) {
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const [selected, setSelected] = useState('');
  const { hasPermission } = usePermission();
  // const L = useRoutePermission().list;
  const L = routeList;
  // const onAssetManagementOpen = translate(`menu.assetManagement`) === selected;
  // const onLedgerOpen = translate(`menu.ledger`) === selected;

  //###add-new-page
  return (
    // <RaMenu
    //   {...props}
    //   style={{
    //     paddingTop: '0',
    //     paddingBottom: '0',
    //     marginTop: '0',
    //     marginBottom: '0',
    //   }}
    // >
    <div style={sx.menuPadding}>
      <div style={sx.logo}>
        <PMPLogo />
      </div>
      <div style={{ height: 'calc(100vh - 9.5rem)' }}>
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t('cobrandManagement')}
          list={[
            L.program,
            L.referralCode,
            L.exchangeRate,
            L.exchangeRateConfiguration,
            L.rewardRateConfiguration,
            L.injectionFeeConfiguration
          ]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t('customerManagement')}
          list={[L.customerList, L.customerAccount, L.customerApplication, L.cardBlockRequest]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t('tcsp')}
          list={[L.tcspAccount, L.tcspAccountApplication, L.assetCustodyWallet]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t('transaction')}
          list={[
            L.assetCustody,
            L.creditAdjustmentRequest,
            L.repaymentBatch,
            L.repaymentSchedule,
            L.tcspTransaction,
          ]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t('reward')}
          list={[
            L.statement,
            L.rewardBatch,
            L.rewardRecord,
            L.qualifiedRepaymentTransaction,
            L.qualifiedRepayment,
            L.redeemHistory,
            L.rebateWalletBalance,
            L.paRebateAdjustmentRequest,
          ]}
        />
        <MenuStack
          selected={selected}
          setSelected={setSelected}
          title={t('priorityPass')}
          list={[L.membership]}
        />
        <Box
          sx={{
            textAlign: 'center',
            color: COLOR_THEME.Menu.Collapsed.Text,
            width: '320px',
            position: 'fixed',
            left: 0,
            bottom: '12px',
          }}
        >
          {t('version')}: {versionNumber}
        </Box>
      </div>
    </div>
    // </RaMenu>
  );
}

export function MenuStack(props: any) {
  const { hasPermission } = usePermission();
  const zusListStore = useZusListStore();
  const resetZusList = () => zusListStore.clear();
  const translate = useTranslate();
  const t = (key: string) => translate(`menu.${key}`);
  const { title, list, selected, setSelected } = props;
  const expanded = selected === title;
  const [selectedPagePathname, setSelectedPagePathname] = useState(
    window.location.pathname.substring(1)
  );
  const toggle = () =>
    setSelected((key: string) => {
      if (key === title) return '';
      return title;
    });
  const canRender = (key: string) => {
    if (key === '*****') return true; //for temporary use
    return hasPermission(key);
  };
  const pendingOperationsCount: {
    merchantOperation: string;
    ledgerDepositRecord: string;
  } = props?.meta?.pendingOperationsCount || {
    merchantOperation: '0',
    ledgerDepositRecord: '0',
  };
  const genItem = ([text, to, permission]: any = []) => {
    return (
      canRender(permission) && (
        <Box sx={{ width: 'calc(100% - .5rem)', display: 'flex' }} key={text}>
          <MenuItemLink
            to={to}
            primaryText={t(text)}
            sx={{ flex: '1' }}
            onClick={
              selectedPagePathname !== to
                ? () => {
                  resetZusList();
                  setSelectedPagePathname(to);
                }
                : undefined
            }
          />
        </Box>
      )
    );
  };
  const subMenus = list.map(genItem);
  const isHidden = subMenus.filter((dom: any) => dom).length === 0;
  if (isHidden) return <></>;
  return (
    <Accordion
      sx={{
        color: expanded ? sx.expanded : sx.notExpanded,
        ...sx.muiAccordion,
      }}
      expanded={expanded}
      onChange={toggle}
    >
      <AccordionSummary
        expandIcon={<ExpandCircleDownIcon />}
        aria-controls={title}
        sx={sx.accordionSummary}
      >
        <Typography variant="h6">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={sx.accordionDetails}>
        <Typography variant="h6">{list.map(genItem)}</Typography>
      </AccordionDetails>
    </Accordion>
  );
}
