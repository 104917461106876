import { useEffect } from 'react';
import { usePermission, useTabs } from '../../hooks';
import { COLOR_THEME } from '../../style/colorTheme';
import { Box } from '../MuiGenerals';

interface IProps {
  contents: {
    element: JSX.Element;
    name: string;
    permissionCode: string;
  }[];
  styleConfig?: {
    backgroundColor?: string;
  };
  forceSelectTab?: string;
}

function PermissionTab(props: IProps) {
  const { contents, styleConfig, forceSelectTab } = props;

  const { hasPermission } = usePermission();

  const filteredTabArr = contents.filter(({ permissionCode }) => hasPermission(permissionCode));

  const { Tabs, tabValue, setTabIndex } = useTabs(
    filteredTabArr.map((item) => {
      return { name: item.name, value: item.name };
    })
  );

  useEffect(() => {
    if (!forceSelectTab) return;

    const tabIndex = filteredTabArr.findIndex(({ name }) => name === forceSelectTab);

    if (tabIndex !== -1) {
      setTabIndex(tabIndex);
    }
  }, [forceSelectTab]);

  if (filteredTabArr.length === 0) {
    return <></>;
  }

  return (
    <Box
      sx={{
        backgroundColor: styleConfig?.backgroundColor || COLOR_THEME.Layout.FilterTable.Background,
        paddingTop: '2rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
        position: 'relative',
      }}
    >
      <Tabs sx={{ margin: '0 1.5rem', borderBottom: 'solid #E1E4EA 1px' }}>
        {filteredTabArr.find(({ name }) => name === tabValue)?.element}
      </Tabs>
    </Box>
  );
}

export default PermissionTab;
