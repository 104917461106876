import { GridRenderCellParams } from '@mui/x-data-grid';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { TableColumnKey } from '../types/TableColumnKeys';
import { usePermission } from '../../../../../hooks';
import { useGenGridCol } from '../../../../../utils/ComponentHelper';
import { COLUMN_WIDTH } from '../../../../../constants/ColumnWidth';

const useTableColumns = () => {
  const { hasPermission } = usePermission();

  const columns = [
    useGenGridCol(TableColumnKey.programAgentId, {}),
    useGenGridCol(TableColumnKey.programName, {}),
    useGenGridCol(TableColumnKey.transactionType, {}),
    useGenGridCol(TableColumnKey.currency, {}),
    useGenGridCol(TableColumnKey.amount, {}),
    useGenGridCol(TableColumnKey.postBalance, {}),
    useGenGridCol(TableColumnKey.remarks, {}),
    useGenGridCol(TableColumnKey.createdBy, {}),
    useGenGridCol(TableColumnKey.creationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
