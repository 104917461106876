export enum TableColumnKey {
  Operation = 'operation',
  ProgramName = 'programName',
  Currency = 'currency',
  FeeType = 'feeType',
  LowerBoundAmount = 'lowerBoundAmount',
  Fee = 'fee',
  CreatedBy = 'createdBy',
  CreationTime = 'creationTime',
  LastModifiedBy = 'lastModifiedBy',
  LastModifiedTime = 'lastModifiedTime',
}
