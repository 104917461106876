import { Dispatch, SetStateAction, useLayoutEffect, useState } from 'react';
import { DialogInOne } from '../../../../../components'
import { IdialogInOneProps } from '../../../../../components/DialogInOne'
import { GridBox } from '../../../../../components/Layout';
import { Box } from '../../../../../components/MuiGenerals';
import FeeTextField from '../../../../common/filterTable/components/Filters/FeeTextField';
import useLocalTranslate from '../../helpers/useLocalTranslate'
import { TableColumnKey } from '../../types/TableColumnKey';
import { TableRow } from '../../types/TableRow';
import FeeTypeSingleSelection from '../../../../common/filterTable/components/Filters/FeeTypeSingleSelection';
import { EditRecordFields } from '../../types/EditRecordField';
import LowerBoundAmountTextField from '../../../../common/filterTable/components/Filters/LowerBoundAmountTextField';
import { removeCommasFromNumStr } from '../../../../../utils';


interface Iprops {
  selectedRow?: TableRow;
  clear: () => void;
  editRecordFields: EditRecordFields;
  setEditRecordFields: Dispatch<SetStateAction<EditRecordFields>>
  handleEditRecordSubmit: () => Promise<void>
}

const DialogEdit = (props: Iprops) => {
  const {
    selectedRow,
    editRecordFields,
    setEditRecordFields,
    handleEditRecordSubmit,
    clear
  } = props;
  const { tc } = useLocalTranslate();


  useLayoutEffect(() => {
    setEditRecordFields({
      [TableColumnKey.FeeType]: selectedRow?.rawData.feeAmountType.toString() || '',
      [TableColumnKey.LowerBoundAmount]: removeCommasFromNumStr(selectedRow?.lowerBoundAmount || ''),
      [TableColumnKey.Fee]: selectedRow?.fee.replaceAll('%', '') || ''
    })
  }, [selectedRow])

  const handleCloseDialog = () => {
    clear();
  };

  const handleSubmit = async () => {
    handleEditRecordSubmit()
  }

  const updateField = (fieldName: string, value: any) => {
    setEditRecordFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TableColumnKey.ProgramName,
      <Box>{selectedRow?.programName}</Box>
    ],
    [
      TableColumnKey.Currency,
      <Box>{selectedRow?.currency}</Box>
    ],
    [
      TableColumnKey.FeeType,
      <FeeTypeSingleSelection
        value={editRecordFields[TableColumnKey.FeeType]}
        onChange={(e) => {
          updateField(TableColumnKey.FeeType, e.target.value)
        }}
      />
    ],
    [
      TableColumnKey.LowerBoundAmount,
      <LowerBoundAmountTextField
        value={editRecordFields[TableColumnKey.LowerBoundAmount]}
        onChange={(e) => {
          updateField(TableColumnKey.LowerBoundAmount, e.target.value)
        }}
      />
    ],
    [
      TableColumnKey.Fee,
      <FeeTextField
        feeType={editRecordFields[TableColumnKey.FeeType]?.toString()}
        value={editRecordFields[TableColumnKey.Fee]}
        onChange={(e) => {
          updateField(TableColumnKey.Fee, e.target.value)
        }}
      />
    ],
  ]

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />

  const dialogConfig: IdialogInOneProps = {
    title: tc('edit'),
    self: {
      open: selectedRow !== undefined,
      onClose: handleCloseDialog
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,
    size: 'sm',
  }
  return (
    <DialogInOne {...dialogConfig} />
  )
}

export default DialogEdit