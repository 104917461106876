import { useState } from 'react';
import { FilterTable } from '../../../components/Layout';
import useInjectionFeeConfiguration from '../../../features/cobrandManagement/injectionFeeConfiguration/helpers/useInjectionFeeConfiguration'
import ViewFilter from '../../../features/cobrandManagement/injectionFeeConfiguration/component/ViewFilter';
import ViewTable from '../../../features/cobrandManagement/injectionFeeConfiguration/component/ViewTable';
import DialogCreateRecord from '../../../features/cobrandManagement/injectionFeeConfiguration/component/Dialog/DialogCreateRecord';
import DialogEdit from '../../../features/cobrandManagement/injectionFeeConfiguration/component/Dialog/DialogEdit';

const InjectionFeeConfiguration = () => {

  const {
    page,
    fields,
    list,
    selectedRow,
    editRecordFields,
    isDialogCreateOpen,
    createRecordFields,
    setFields,
    onExport,
    onPageChange,
    onFilterSearch,
    setSelectedRow,
    setEditRecordFields,
    handleEditRecordSubmit,
    setIsDialogCreateOpen,
    setCreateRecordFields,
    handleCreateRecordSubmit
  } = useInjectionFeeConfiguration();

  return (
    <FilterTable
      filter={<ViewFilter
        setIsDialogCreateOpen={() => setIsDialogCreateOpen(true)}
        fields={fields}
        setFields={setFields}
        onSearch={onFilterSearch}
      />}
      table={
        <>
          <DialogEdit
            editRecordFields={editRecordFields}
            setEditRecordFields={setEditRecordFields}
            selectedRow={selectedRow}
            handleEditRecordSubmit={handleEditRecordSubmit}
            clear={() => setSelectedRow(undefined)}
          />
          <DialogCreateRecord
            createRecordFields={createRecordFields}
            isOpen={isDialogCreateOpen}
            closeDialog={() => setIsDialogCreateOpen(false)}
            setCreateRecordFields={setCreateRecordFields}
            handleCreateRecordSubmit={handleCreateRecordSubmit}
          />
          <ViewTable
            list={list}
            page={page}
            onPageChange={onPageChange}
            onExport={onExport}
            setSelectedRow={setSelectedRow}
          />
        </>
      }
    />
  );
};

export default InjectionFeeConfiguration;
