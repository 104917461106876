import { useEffect, useState } from 'react';
import { SingleSelection } from '../../../../../components';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import useAssets from '../../../../../hooks/useAssets/useAssets';
import APIs from '../../../../../api';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const ProgramAgentIdSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;

  const [programAgentIdListRes, setProgramAgentIdListRes] = useState<string[]>();

  const { tc } = useTranslation();

  const fetchProgramAgentIdList = async () => {
    const res = await APIs.MerchantPortal.query.getProgramAgentIdList();

    if (!res) return;

    setProgramAgentIdListRes(res);
  };

  const createProgramAgentIdListEnum = () => {
    if (!programAgentIdListRes) return {};

    const enumData: Record<string, string> = {};

    programAgentIdListRes.forEach((programAgentId) => {
      enumData[programAgentId] = programAgentId;
    });

    return enumData;
  };

  const Enum = createProgramAgentIdListEnum();

  useEffect(() => {
    fetchProgramAgentIdList();
  }, []);

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('programAgentId') })}
      value={value}
      clearSelect={onClear}
      onChange={onChange}
      enumData={Enum}
      nameFn={(item) => item}
    />
  );
};

export default ProgramAgentIdSingleSelection;
