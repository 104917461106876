import { EnumRewardType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward";
import { SingleSelection } from "../../../../../components";
import { SelectChangeEvent } from "../../../../../components/MuiGenerals";
import { useTranslation } from "../../../../../hooks";

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const RewardTypeSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;
  const { t, tc, translate } = useTranslation('rewardRecord');
  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('type') })}
      value={value}
      onChange={onChange}
      enumData={EnumRewardType}
      clearSelect={onClear}
      nameFn={(name) => translate(`enumConstants.${name}`)}
    />
  )
}

export default RewardTypeSingleSelection