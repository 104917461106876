import { EnumPaTransType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';
import { ICreateRequestFields } from '..';
import { useTranslation } from '../../../../../../hooks';
import { ValidationConfigProps } from '../../../../../../hooks/useValidation/types';
import { requiredValidator } from '../../../../../../utils/validators/requiredValidator';
import useValidation from '../../../../../../hooks/useValidation';

interface IProps {
  fields: ICreateRequestFields;
}

const useCreateRequestDialogValidation = (props: IProps) => {
  const { fields } = props;

  const { translate, t } = useTranslation('paRebateAdjustmentRequest');

  const validationConfig: ValidationConfigProps = [
    {
      key: 'programAgentId',
      value: fields['programAgentId'],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('programAgentId'),
          })
        ),
      ],
    },
    {
      key: 'transactionType',
      value: fields['transactionType'],
      validators: [
        requiredValidator(
          translate('validation.selectRequired', {
            fieldName: t('transactionType'),
          })
        ),
      ],
    },
    {
      key: 'amount',
      value: fields['amount'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('amount'),
          })
        ),
      ],
    },
    {
      key: 'remarks',
      value: fields['remarks'],
      validators: [
        requiredValidator(
          translate('validation.inputRequired', {
            fieldName: t('remarks'),
          }),
          Number(fields.transactionType) === EnumPaTransType.Others
        ),
      ],
    },
  ];

  return useValidation(validationConfig);
};

export default useCreateRequestDialogValidation;
