import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKey';
import { TableRow } from '../types/TableRow';
import { getInjectionFeeConfigurationFeeAmountType } from '../../../../helper/getInjectionFeeConfigurationFeeAmountType';
import { EnumInjectionFeeAmountType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];


    const tableRows = apiList?.rows.map((row: GetListRes, index: any): TableRow => {
      const feeAmountType = getInjectionFeeConfigurationFeeAmountType(
        row.feeAmountType,
        translate
      );

      const toLowerBoundAmount = displayAmountCurrying(row.custodyAmountDecimals);
      const toFeeAmount = displayAmountCurrying(0);



      const displayFeeAmount = () => {
        const value = toFeeAmount(row.feeAmount)
        if (row.feeAmountType === EnumInjectionFeeAmountType.Percentage) {
          return `${value}%`
        }
        return value
      }
      const feeAmount = displayFeeAmount()

      return {
        id: index,
        [TableColumnKey.ProgramName]: row.programName,
        [TableColumnKey.Currency]: row.currency,
        [TableColumnKey.FeeType]: feeAmountType,
        [TableColumnKey.LowerBoundAmount]: toLowerBoundAmount(row.lowerBoundCustodyAmount),
        [TableColumnKey.Fee]: feeAmount,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedBy]: row.lastModifiedBy,
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row
      };
    })

    return tableRows

  }

  const rows = convertToTableRows(apiList);

  return {
    rows,
    totalCounts: apiList.count,
    convertToTableRows
  };
};

export default useTableRows;
