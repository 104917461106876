import { convertRewardRequestFilterMonthFormat } from '../../../../utils';
import { defaultListParams } from '../../../common/filterTable/constants/defaultListParams';
import { cleanParams } from '../../../common/filterTable/helpers/cleanParams';
import { FilterFields } from '../types/FilterFields';
import { GetListParams } from '../types/GetListParams';

const useGetListParams = () => {
  const getListParams = (fields: FilterFields) => {
    const {
      createdDateFrom,
      createdDateTo,
      lastModifiedTimeFrom,
      lastModifiedTimeTo,
      programNames,
      customerNumber,
      rewardOrderId,
      rewardBatchId,
      month,
      type
    } = fields;

    const params: GetListParams = {
      ...defaultListParams,
      createdDateFrom: createdDateFrom,
      createdDateTo: createdDateTo,
      lastModifiedDateFrom: lastModifiedTimeFrom,
      lastModifiedDateTo: lastModifiedTimeTo,
      programNames,
      customerNumber,
      rewardOrderId,
      rewardBatchId,
      month: convertRewardRequestFilterMonthFormat(month),
      type
    };

    return cleanParams(params);
  };

  return {
    getListParams,
  };
};

export default useGetListParams;
