import { EptReward } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';

import axios from '../axiosInstance';
import { ApproveHistory } from '../../features/approval/types/ApproveHistory';

async function getAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptReward.PaRebateAdjustmentRequest.getAll, input);
}

async function getExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(EptReward.PaRebateAdjustmentRequest.export, input);
}

const createRequest = async (
  params: { programAgentId: string; transactionType: number; amount: string; remarks: string },
  config?: any
) => {
  const input = {
    ...params,
    currency: 'HKD',
    ...config,
  };

  return await axios.post(EptReward.PaRebateAdjustmentRequest.create, input);
};

const getApproveDetails = async (params: { requestRecordId: string }) => {
  return await axios.get<any, ApproveHistory[]>(EptReward.PaRebateAdjustmentRequest.detail, {
    params,
  });
};

const approve = async (params: { id: number; remarks?: string }) => {
  return await axios.post(EptReward.PaRebateAdjustmentRequest.approve, { ...params });
};

const reject = async (params: { id: number; remarks?: string }) => {
  return await axios.post(EptReward.PaRebateAdjustmentRequest.reject, { ...params });
};

export default { getAll, getExport, createRequest, getApproveDetails, approve, reject };
