import { ExportBtn, OpIconButton } from '../../../../components/Button'
import { usePermission } from '../../../../hooks'
import TableContainer from '../../../common/filterTable/layout/TableContainer'
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import Table from '../../../common/filterTable/components/Table';
import useLocalTranslate from '../helpers/useLocalTranslate';
import useTableColumns from '../helpers/useTableColumns';
import useTableRows from '../helpers/useTableRows';
import { ApiResult } from '../../../../api/types';
import { GetListRes } from '../types/GetListRes';
import { OpEdit } from '../../../../assets/icons';
import { GridRenderCellParams } from '@mui/x-data-grid';
import DialogEdit from './Dialog/DialogEdit';
import { Dispatch, SetStateAction, useState } from 'react';
import { TableRow } from '../types/TableRow';

interface ViewTableProps {
  list: ApiResult<GetListRes>
  page: number
  onPageChange: (page: number) => void
  onExport: () => Promise<void>
  setSelectedRow: Dispatch<SetStateAction<TableRow | undefined>>
}

const ViewTable = (props: ViewTableProps) => {

  const { list, page, onPageChange, onExport, setSelectedRow } = props;
  const { tc, Tabs } = useLocalTranslate();

  const { hasPermission } = usePermission();
  const isShowExportBtn = hasPermission(FeatureCode.CobrandManagement.InjectionFeeConfiguration.Export);

  const renderOperationsCell = (params: GridRenderCellParams<any, any, any>) => {
    return (
      <OpIconButton
        title={tc('edit')}
        svgUrl={OpEdit}
        onClick={() => setSelectedRow(params.row)} />
    )
  }

  const { columns } = useTableColumns({ renderOperationsCell })
  const { rows, totalCounts } = useTableRows({ apiList: list });

  return (
    <TableContainer>
      <ExportBtn
        onExport={onExport}
        isShow={isShowExportBtn}
      />
      <Tabs>
        <Table
          columns={columns}
          rows={rows}
          rowCount={totalCounts}
          page={page}
          onPageChange={onPageChange}
        />
      </Tabs>
    </TableContainer>
  )
}

export default ViewTable