import { useEffect, useState } from 'react';

import {
  EnumApprovalStatus,
  EnumCustomerApplicationType,
  EnumIdvMethod,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne, MpTextField } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { Box } from '../../../components/MuiGenerals';
import ApproveButton from '../../../features/approval/components/ApproveButton';
import CancelButton from '../../../features/approval/components/CancelButton';
import CloseButton from '../../../features/approval/components/CloseButton';
import RejectButton from '../../../features/approval/components/RejectButton';
import { ApproveHistory } from '../../../features/approval/types/ApproveHistory';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import { useAppSelector } from '../../../reducer/hooks';
import { selectProfile } from '../../../reducer/profileSlice';
import { PALETTE } from '../../../style/colorTheme';
import { importantStyle, transformObjWithNA } from '../../../utils';
import { useZusDialogStore } from '../../../zustand/store';
import FullScreenDialogBtn from './components/FullScreenDialogBtn';
import OperationDialogContent from './components/OperationDialogContent';
import useOperationDialogContent from './components/useOperationDialogContent';
import {
  apiObj as api,
  EnumOperationDialogMode,
  IanswersEditApiParams,
  IdetailsApiFullRes,
  IdetailsApiRes,
  IgeneralInfoEditApiParams,
  ItableApiRes,
  PermissionKey,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';

const initFields = {
  // const initFieldsWithoutIdNumber = {
  // programName: '',
  // referralCode: '',
  title: '',
  firstName: '',
  lastName: '',
  chineseName: '',
  alias: '',
  gender: '',
  dateOfBirth: '',
  nationality: '',
  identificationDocumentType: '',
  identificationDocumentTypeOthers: '',
  placeOfIssue: '',
  identificationNumber: '',

  dateOfIssue: '',
  dateOfExpiry: '',
  usCitizenship: '',
  maritalStatus: '',
  educationalStatus: '',
  educationalStatusOthers: '',
  purposeForApplying: '',
  otherPurpose: '',
  isAuthorizedByYou: '',
  employmentStatus: '',
  companyName: '',
  jobTitle: '',
  jobTitleOthers: '',
  businessNatureOrIndustry: '',
  otherIndustry: '',
  employmentStartDate: '',
  officeTelephoneNumber: '',
  monthlySalaryInHkd: '',
  otherMonthlyIncome: '',
  residentialStatus: '',
  otherStatus: '',
  residentialTelephoneNumber: '',

  residentialAddressLine1: '',
  residentialAddressLine2: '',
  residentialAddressLine3: '',
  residentialPostalCode: '',
  residentialCity: '',
  residentialCountry: '',
  deliveryAddressLine1: '',
  deliveryAddressLine2: '',
  deliveryAddressLine3: '',
  deliveryPostalCode: '',
  deliveryCity: '',
  deliveryCountry: '',
};

type IgetAllApi = Omit<
  ItableApiRes & { customerApplicationId: string },
  'customer_application_approvals'
>;

export default function DialogOperation() {
  const zusDialog = useZusDialogStore();
  const { hasPermission } = usePermission();

  const { t, te, tc, tcc, tb } = useTranslation(translatePrefix);

  const {
    dialogMode,
    customer_application_approvals = [],
    ...rawGetAllApiRes
  } = zusDialog.meta as ItableApiRes & {
    customerApplicationId: string;
    customer_application_approvals: ApproveHistory[];
    dialogMode: EnumOperationDialogMode;
  };

  const getAllApiRes = transformObjWithNA(rawGetAllApiRes, true);

  const { customerApplicationId, customerNumber, customerLevel } = getAllApiRes;

  const [apiRes, setApiRes] = useState<IdetailsApiRes | undefined>(undefined);

  const [remarks, setRemarks] = useState('');

  const [isEditMode, setIsEditMode] = useState(false);

  const { email: opeartingEmail } = useAppSelector(selectProfile);

  const { alerting } = useAlerting();

  const zusParams = useZusParams();

  const isDetailsOrManualVerifyIdv =
    dialogMode === EnumOperationDialogMode.details ||
    dialogMode === EnumOperationDialogMode.manualVerifyIdv;

  const hasViewIdNumberPermission = hasPermission(PermissionKey.Details.ViewIdNumber);

  // const initFields = hasViewIdNumberPermission
  //   ? { ...initFieldsWithoutIdNumber, identificationNumber: '' }
  //   : initFieldsWithoutIdNumber;

  const {
    getValidationMessage,
    labelElePairArrWithoutAddressSection,
    addressSectionLabelElePairArr,
    fields,
    setFields,
    setIsSameAddress,
    isSameAddress,
  } = useOperationDialogContent<typeof initFields>({
    mode: 'edit',
    applicationAnswers: apiRes,
    generalDetail: getAllApiRes,
    initFields,
    hasEditBtn: { editEnabled: isEditMode },
  });

  useEffect(() => {
    if (!apiRes) {
      return;
    }

    api.decryptIdNumber({ applicationNumber: getAllApiRes.applicationNumber }).then((res) => {
      if (!res) {
        return;
      }

      setFields((prev) => {
        const returnObj = {
          ...prev,
          // programName: getAllApiRes.programName,
          // referralCode: getAllApiRes.referralCode,
          title: String(apiRes.title),
          firstName: getAllApiRes.firstName,
          lastName: getAllApiRes.lastName,
          chineseName: apiRes.chineseName,
          alias: apiRes.alias,
          gender: apiRes.gender,
          dateOfBirth: getAllApiRes.dateOfBirth,
          nationality: apiRes.nationality,
          identificationDocumentType: String(getAllApiRes.idType),
          identificationDocumentTypeOthers: apiRes.idTypeOthers,
          placeOfIssue: getAllApiRes.idIssuedBy,
          identificationNumber: res.idNumber,

          dateOfIssue: apiRes.idDateOfIssue,
          dateOfExpiry: apiRes.idDateOfExpiry,
          usCitizenship: apiRes.usCitizenship ? 'yes' : 'no',
          maritalStatus: String(apiRes.maritalStatus),
          educationalStatus: String(apiRes.educationLevel),
          educationalStatusOthers: apiRes.educationLevelOthers,
          purposeForApplying: String(apiRes.purposeForApplying),
          otherPurpose: apiRes.purposeForApplyingOthers,
          isAuthorizedByYou: apiRes.authorizedToThirdParty ? 'yes' : 'no',
          employmentStatus: String(apiRes.employmentStatus),
          companyName: apiRes.companyName,
          jobTitle: apiRes.jobTitle ? String(apiRes.jobTitle) : '',
          jobTitleOthers: apiRes.jobTitleOthers,
          businessNatureOrIndustry: apiRes.industry ? String(apiRes.industry) : '',
          otherIndustry: apiRes.industryOthers,
          employmentStartDate: apiRes.employmentStartDate,
          officeTelephoneNumber: apiRes.officeTelephoneNumber,
          monthlySalaryInHkd: apiRes.monthlySalaryHKD,
          otherMonthlyIncome: apiRes.otherIncomeHKD,
          residentialStatus: String(apiRes.residentialStatus),
          otherStatus: apiRes.residentialStatusOthers,
          residentialTelephoneNumber: apiRes.residentialTelephoneNumber,
          residentialAddressLine1: apiRes.residentialAddressLine1,
          residentialAddressLine2: apiRes.residentialAddressLine2,
          residentialAddressLine3: apiRes.residentialAddressLine3,
          residentialPostalCode: apiRes.residentialPostalCode,
          residentialCity: apiRes.residentialCity,
          residentialCountry: apiRes.residentialCountry,
          deliveryAddressLine1: apiRes.deliveryAddressLine1,
          deliveryAddressLine2: apiRes.deliveryAddressLine2,
          deliveryAddressLine3: apiRes.deliveryAddressLine3,
          deliveryPostalCode: apiRes.deliveryPostalCode,
          deliveryCity: apiRes.deliveryCity,
          deliveryCountry: apiRes.deliveryCountry,
        };

        // if (!hasViewIdNumberPermission) {
        //   delete returnObj.identificationNumber;
        // }

        return returnObj;
      });
    });
  }, [apiRes]);

  useEffect(() => {
    if (!zusDialog.match('operationDialog')) {
      return;
    }
    const fetch = async () => {
      const res = (await api.details({ customerApplicationId })) as
        | IdetailsApiFullRes
        | null
        | undefined;

      if (!res) {
        if (res === null) {
          alerting('error', tc('no_data_found'));
        }
        return;
      }

      const transformedResponse = transformObjWithNA(res.answers, false);

      if (!transformedResponse) {
        alerting('error', tc('no_data_found'));
      }

      setApiRes(transformedResponse);
    };

    fetch();
  }, [customerApplicationId, zusDialog.main]);

  if (!apiRes) {
    return <></>;
  }

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setApiRes(undefined);
    setFields(initFields);
    setIsEditMode(false);
    setIsSameAddress(false);
    setRemarks('');
  };

  const handleApproveReject = (mode: 'approve' | 'reject') => async () => {
    const rawApprovalArr = customer_application_approvals;

    const isApprove = mode === 'approve';

    const apiFn = isApprove ? api.approve : api.reject;

    const successMessage = isApprove ? tc('request_approved') : tc('request_rejected');

    const sortedApprovalArr = rawApprovalArr.sort((a, b) => a.seq - b.seq);

    const firstPending = sortedApprovalArr.find(
      ({ status }) => status === EnumApprovalStatus.Pending
    );

    const firstPendingSeq = firstPending?.seq;

    const firstPendingPermission = firstPending?.approvalPermission;

    if (isApprove) {
      if (!firstPendingPermission) {
        return alerting('error', 'System Error');
      }
      if (!hasPermission(firstPendingPermission)) {
        return alerting('error', tc('no_relevant_permission'));
      }
      if (getAllApiRes.createdBy === opeartingEmail) {
        return alerting('error', tc('no_same_operator_creator'));
      }

      if (rawApprovalArr.find(({ approvedBy }) => approvedBy === opeartingEmail)) {
        return alerting('error', tc('no_same_approver'));
      }

      if (firstPendingSeq === getAllApiRes.approvalsRequired && !customerNumber) {
        return alerting('error', t('alert_no_customer_number'));
      }
    } else {
      if (!hasPermission(PermissionKey.Details.Reject)) {
        return alerting('error', tc('no_relevant_permission'));
      }
    }

    const id = firstPending?.id || -1;

    const res = await apiFn({
      id,
      remarks,
      customerNumber,
    });
    if (!res) {
      return;
    }

    alerting('success', successMessage);
    await handleCloseDialog();
    zusParams.refetch();
  };

  const handleManualVerifyIdv = async () => {
    if (!hasPermission(PermissionKey.Details.ManualReviewIdv.Success)) {
      return alerting('error', t(TK.noPermission));
    }
    const { applicationNumber } = getAllApiRes;
    const res = await api.manualVerifyIdv({ applicationNumber, remarks });
    if (!res) {
      return;
    }

    handleCloseDialog();
    alerting('success', t(TK.successManualVerifyIdv));
    zusParams.refetch();
  };

  const handleFailManualVerifyIdv = async () => {
    if (!hasPermission(PermissionKey.Details.ManualReviewIdv.Fail)) {
      return alerting('error', t(TK.noPermission));
    }

    const { applicationNumber } = getAllApiRes;

    const res = await api.manualFailIdv({ applicationNumber, remarks });
    if (!res) {
      return;
    }

    await handleCloseDialog();
    alerting('success', t(TK.failManualVerifyIdv));
    zusParams.refetch();
  };

  const handleRetryManualVerifyIdv = async () => {
    if (!hasPermission(PermissionKey.Details.ManualReviewIdv.Retry)) {
      return alerting('error', t(TK.noPermission));
    }

    const { applicationNumber } = getAllApiRes;
    const res = await api.retryIdv({ applicationNumber, remarks });
    if (!res) {
      return;
    }

    await handleCloseDialog();
    alerting('success', t(TK.successRetryIdv));
    zusParams.refetch();
  };

  const handleEdit = async () => {
    const {
      firstName,
      lastName,
      // programName,
      placeOfIssue: idIssuedBy,
      identificationDocumentType: idType,
      identificationNumber: idNumber,
      dateOfBirth,
      // referralCode,
      ...rawAnswers
    } = fields;
    const enumNumberFields: (keyof typeof rawAnswers)[] = [
      'businessNatureOrIndustry',
      'educationalStatus',
      'jobTitle',
      'maritalStatus',
      'purposeForApplying',
      'residentialStatus',
      'title',
      'employmentStatus',
    ];
    const enumBooleanFields: (keyof typeof rawAnswers)[] = ['usCitizenship', 'isAuthorizedByYou'];
    const answersEntries = Object.entries(rawAnswers).map(([key, val]) => {
      const typedKey = key as keyof typeof rawAnswers;
      if (enumNumberFields.find((enumKey) => enumKey === typedKey)) {
        if (
          typedKey === 'jobTitle' ||
          typedKey === 'residentialStatus' ||
          typedKey === 'businessNatureOrIndustry'
        ) {
          return val ? [typedKey, Number(val)] : [typedKey, 0];
        }

        return val ? [typedKey, Number(val)] : [typedKey, null];
      }

      if (enumBooleanFields.find((enumKey) => enumKey === typedKey)) {
        return val ? [typedKey, val === 'yes'] : [typedKey, null];
      }

      return [typedKey, val];
    });

    const answers = Object.fromEntries(answersEntries);

    const {
      isAuthorizedByYou: authorizedToThirdParty,
      identificationDocumentTypeOthers: idTypeOthers,
      dateOfIssue: idDateOfIssue,
      dateOfExpiry: idDateOfExpiry,
      educationalStatus: educationLevel,
      educationalStatusOthers: educationLevelOthers,
      otherPurpose: purposeForApplyingOthers,
      jobTitle,
      businessNatureOrIndustry: industry,
      otherIndustry: industryOthers,
      monthlySalaryInHkd: monthlySalaryHKD,
      otherMonthlyIncome: otherIncomeHKD,
      otherStatus: residentialStatusOthers,
      ...rest
    } = answers;

    const generalInfoApiParams: IgeneralInfoEditApiParams = {
      firstName,
      lastName,
      programName: getAllApiRes.programName,
      customerId: getAllApiRes.customerId || '',
      idIssuedBy: idIssuedBy || '***N/A***',
      idType: Number(idType),
      idNumber,
      dateOfBirth,
      referralCode: getAllApiRes.referralCode,
    };

    const answersApiParams: IanswersEditApiParams = {
      authorizedToThirdParty,
      idTypeOthers,
      idDateOfIssue,
      idDateOfExpiry,
      jobTitle,
      educationLevel,
      educationLevelOthers,
      purposeForApplyingOthers,
      industry,
      industryOthers,
      monthlySalaryHKD,
      otherIncomeHKD,
      residentialStatusOthers,
      sameAsResidentialAddress: isSameAddress,
      tcspApplication: apiRes?.tcspApplication,
      ...rest,
    };

    const getIsEdited = (checkObject: IgeneralInfoEditApiParams & IanswersEditApiParams) => {
      return Object.entries(checkObject).find(([key, value]) => {
        const typedKey = key as keyof IgeneralInfoEditApiParams & keyof IanswersEditApiParams;

        if (typedKey === 'sameAsResidentialAddress') {
          return false;
        }

        const answersValue = apiRes[typedKey];

        if (answersValue !== undefined) {
          return (
            answersValue !== value &&
            !(
              (value === '' && answersValue === '***N/A***') ||
              (answersValue === '' && value === '***N/A***')
            )
          );
        }

        const generalInfoValue = getAllApiRes[typedKey];

        if (generalInfoValue !== undefined) {
          return (
            generalInfoValue !== value &&
            !(
              (value === '' && generalInfoValue === '***N/A***') ||
              (generalInfoValue === '' && value === '***N/A***')
            )
          );
        }

        return false;
      });
    };

    const isEdited = getIsEdited({ ...generalInfoApiParams, ...answersApiParams });

    if (!isEdited) {
      return alerting('warning', tc('no_changes_made_warning'));
    }

    const validateMessage = getValidationMessage();

    if (validateMessage) {
      return alerting('warning', validateMessage);
    }

    const res = await api.resubmit({
      ...generalInfoApiParams,
      answers: answersApiParams,
      customer_application_approvals,
      customerNumber,
      customerLevel,
      applicationNumber: getAllApiRes.applicationNumber,
      rejectApplicationRemark: remarks || t(TK.editApplicationRemarks),
    });

    if (!res) {
      return;
    }

    alerting('success', t(TK.editSuccessfulMessage));

    await handleCloseDialog();
    zusParams.refetch();
  };

  const isOnfidoIdvMethod = getAllApiRes.kycIdvMethod === EnumIdvMethod.Onfido;

  const isEditBtnShown =
    getAllApiRes.type === EnumCustomerApplicationType.CreateAccount ||
    getAllApiRes.type === EnumCustomerApplicationType.Registration;

  const dialogConfig: IdialogInOneProps = {
    title: '',
    self: {
      open: zusDialog.match('operationDialog'),
      onClose: handleCloseDialog,
    },
    content: (
      <OperationDialogContent
        labelElePairArrWithoutAddressSection={labelElePairArrWithoutAddressSection}
        addressSectionLabelElePairArr={addressSectionLabelElePairArr}
        isApprovalProgressShown={dialogMode === EnumOperationDialogMode.approval}
        customer_application_approvals={customer_application_approvals}
        isSameAddressBtnEnabled={isEditMode}
        isSameAddress={isSameAddress}
        setIsSameAddress={setIsSameAddress}
        isIdvSectionHidden={isEditMode}
      />
    ),
    onConfirm: () => {},
    onCancel: () => {},
    size: 'xl',
    isFullScreen: true,
    isCancelHidden: true,
    isConfirmHidden: true,
    dialogActionStyling: {
      padding: /* isDetailsOrManualVerifyIdv ? '8px' : */ '16px 0px 16px 8px',
      borderTop: /* isDetailsOrManualVerifyIdv ? undefined : */ '1px solid #DDE1E3',
    },
    actionButtons: (
      <Box
        sx={{
          display: 'flex',
          justifyContent: isDetailsOrManualVerifyIdv ? 'flex-end' : 'space-between',
          width: '100%',
          padding: '0 calc(2rem - 8px)',
        }}
      >
        {dialogMode === EnumOperationDialogMode.approval && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Box>{tc('remarks')}</Box>
              <MpTextField
                sx={{
                  '>div': { backgroundColor: importantStyle('#ECEEF0') },
                  marginLeft: '12px',
                  marginRight: '24px',
                  flex: 1,
                }}
                onChange={(e) => setRemarks(e.currentTarget.value)}
                value={remarks}
              />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {!isEditMode && (
                <>
                  <ApproveButton onClick={handleApproveReject('approve')} />

                  <RejectButton onClick={handleApproveReject('reject')} />

                  {isEditBtnShown && (
                    <FullScreenDialogBtn
                      children={tc('edit_btn')}
                      btnBgColor={PALETTE.Button3}
                      onClick={() => {
                        if (!hasPermission(PermissionKey.Details.Edit)) {
                          return;
                        }

                        setIsEditMode(true);
                      }}
                    />
                  )}
                </>
              )}

              {isEditMode && (
                <FullScreenDialogBtn
                  children={tc('confirm_btn')}
                  btnBgColor={'#5183FF'}
                  onClick={handleEdit}
                />
              )}

              <CancelButton onClick={handleCloseDialog} />
            </Box>
          </>
        )}

        {dialogMode === EnumOperationDialogMode.details && (
          <CloseButton onClick={handleCloseDialog} />
        )}
        {dialogMode === EnumOperationDialogMode.manualVerifyIdv && (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
              <Box>{tc('remarks')}</Box>
              <MpTextField
                sx={{
                  '>div': { backgroundColor: importantStyle('#ECEEF0') },
                  marginLeft: '12px',
                  marginRight: '24px',
                  flex: 1,
                }}
                onChange={(e) => setRemarks(e.currentTarget.value)}
                value={remarks}
              />
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FullScreenDialogBtn
                children={t(TK.success)}
                btnBgColor={'#5183FF'}
                onClick={handleManualVerifyIdv}
              />
              <FullScreenDialogBtn
                children={t(TK.fail)}
                btnBgColor={'#E86A62'}
                onClick={handleFailManualVerifyIdv}
              />

              {isOnfidoIdvMethod && (
                <FullScreenDialogBtn
                  children={t(TK.retry)}
                  btnBgColor={PALETTE.Button3}
                  onClick={handleRetryManualVerifyIdv}
                />
              )}
              <CloseButton onClick={handleCloseDialog} />
            </Box>
          </>
        )}
      </Box>
    ),
  };

  return <DialogInOne {...dialogConfig} />;
}
