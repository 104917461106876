import { EnumRewardAdjustmentStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';
import { ApiResult } from '../../../../api/types';
import getDisplayApprovalProgress from '../../../../features/approval/helpers/getDisplayApprovalProgress';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;

  const { te, tb, tc, translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const toDisplayAmount = displayAmountCurrying(0, 2);

    const getStatusDisplayValue = (status: number) => {
      const value = EnumRewardAdjustmentStatus[status];

      if (!value) return '';

      return translate(`enumConstants.${value}`);
    };

    const getAmountDisplayValue = (amount: string, currency: string) => {
      return `${toDisplayAmount(amount)} ${currency}`;
    };

    const tableRows = apiList?.rows.map((row, index): TableRow => {
      const approvalProgressDisplayValue = getDisplayApprovalProgress({
        approvalsCount: row.approvalsCount,
        approvalsRequired: row.approvalsRequired,
        applicationStatus: row.status,
        translateFunc: tc,
      });

      const statusDisplayValue = getStatusDisplayValue(row.status);
      const amountDisplayValue = getAmountDisplayValue(row.amount, row.currency);

      return {
        id: index,
        [TableColumnKey.ProgramAgentId]: row.programAgentId,
        [TableColumnKey.ApprovalProgress]: approvalProgressDisplayValue,
        [TableColumnKey.Amount]: amountDisplayValue,
        [TableColumnKey.Status]: statusDisplayValue,
        [TableColumnKey.Remarks]: row.remarks,
        [TableColumnKey.CreatedBy]: row.createdBy,
        [TableColumnKey.CreationTime]: toDisplayTime(row.createdDate),
        [TableColumnKey.LastModifiedTime]: toDisplayTime(row.lastModifiedDate),
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
