import { ApiResult } from '../../../../../api/types';
import { useTranslation } from '../../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { te } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const toDisplayAmount = displayAmountCurrying(0, 2);

      const createDate = toDisplayTime(row.createdDate);
      const lastModifiedTime = toDisplayTime(row.lastModifiedDate);

      return {
        id: index,
        [TableColumnKey.programAgentId]: row.programAgentId,
        [TableColumnKey.currency]: row.currency,
        [TableColumnKey.balance]: toDisplayAmount(row.balance),
        [TableColumnKey.lastTransactionId]: String(row.lastTransId),
        [TableColumnKey.createdDate]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
