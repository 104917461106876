export enum FilterFieldsKeys {
  createdDateFrom = 'createdDateFrom',
  createdDateTo = 'createdDateTo',
  lastModifiedTimeFrom = 'lastModifiedTimeFrom',
  lastModifiedTimeTo = 'lastModifiedTimeTo',
  ProgramAgentId = 'programAgentId',
  ProgramName = 'programName',
  TransactionType = 'transactionType',
}

export interface FilterFields {
  [FilterFieldsKeys.createdDateFrom]: string;
  [FilterFieldsKeys.createdDateTo]: string;
  [FilterFieldsKeys.lastModifiedTimeFrom]: string;
  [FilterFieldsKeys.lastModifiedTimeTo]: string;
  [FilterFieldsKeys.ProgramAgentId]: string;
  [FilterFieldsKeys.ProgramName]: string[];
  [FilterFieldsKeys.TransactionType]: string;
}
