import { useMemo } from 'react';

import { EnumCustomerApplicationType, EnumQuestionType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { OpRedirect } from '../../../assets/icons';
import { DialogInOne, OpIconButton } from '../../../components';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box } from '../../../components/MuiGenerals';
import { useAlerting, useTranslation } from '../../../hooks';
import { IenumKey } from '../../../hooks/useTranslation';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  ItableApiRes,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams
} from './config';

export default function DialogSwitchManualReview() {
  const zusDialog = useZusDialogStore();

  const { t, te, tc, tqt } = useTranslation(translatePrefix);

  const zusParams = useZusParams();

  const { alerting } = useAlerting();

  const {
    id,
    applicationNumber,
    type,
    programName,
    customerNumber,
    firstName,
    lastName,
    email,
    referralCode,
    applicationReferralCode, // questionType can be found here
    programAgentId,
    distributorAgentId,
    phoneCountryCode,
    phoneNumber,
    kycRef,
    customerLevel,
  } = zusDialog.meta as ItableApiRes & {
    customerLevel: string;
  };

  const displayPhoneNumber = useMemo(() => {
    if (!phoneCountryCode || !phoneNumber) {
      return '';
    }
    return phoneCountryCode + ' ' + phoneNumber;
  }, [phoneCountryCode, phoneNumber]);

  const displayCustomerName = useMemo(() => {
    if (!firstName || !lastName) {
      return '';
    }
    return firstName + ' ' + lastName;
  }, [firstName, lastName]);

  const redirectHandler = () =>
    window.open(
      `${process.env.REACT_APP_ONFIDO_REDIRECT_URL}${kycRef?.workflowRunId}`,
      '_blank',
      'noopener,noreferrer'
    );

  const labelElePairArr = [
    [TK.applicationNumber, <Box>{applicationNumber}</Box>],
    [TK.applicationType, <Box>{te(EnumCustomerApplicationType[type] as IenumKey)}</Box>],
    [TK.programName, <Box>{programName}</Box>],
    [TK.referralCode, <Box>{referralCode}</Box>],
    [TK.questionType, <Box>{tqt(EnumQuestionType[Number(applicationReferralCode?.questionType)])}</Box>],
    [TK.programAgentId, <Box>{programAgentId}</Box>],
    [TK.distributorAgentId, <Box>{distributorAgentId}</Box>],
    [TK.customerNumber, <Box>{customerNumber}</Box>],
    [TK.customerLevel, <Box>{customerLevel}</Box>],
    [TK.customerName, <Box>{displayCustomerName}</Box>],
    [TK.emailAddress, <Box>{email}</Box>],
    [TK.phoneNumber, <Box>{displayPhoneNumber}</Box>],
    [TK.applicantId, <Box>{kycRef?.applicantId}</Box>],
    [
      TK.workflowRunId,
      <Box sx={{ display: 'flex', justifyContent: 'start', alignItems: 'center' }}>
        <Box>{kycRef?.workflowRunId}</Box>
        {kycRef?.workflowRunId && (
          <OpIconButton
            title={''}
            svgUrl={OpRedirect}
            size="1.2rem"
            sxBox={{ marginLeft: '4px' }}
            onClick={redirectHandler}
          />
        )}
      </Box>,
    ],
  ] as Array<[string, JSX.Element]>;

  const dialogContent = (
    <GridBox
      labelElePairArr={labelElePairArr}
      // containerSx={{ padding: '16px 24px', backgroundColor: '#ECEEF0' }}
      // dividingLineColor={'#DDE1E3'}
      columnCount={1}
    />
  );

  const handleCloseDialog = async () => {
    await zusDialog.close();
  };

  const handleSubmit = async () => {
    const res = await api.switchManualReview({ applicationNumber });
    if (!res) {
      return;
    }

    await handleCloseDialog();
    alerting('success', t(TK.successManualReviewSwitch));
    zusParams.refetch();
  };

  const dialogConfig: IdialogInOneProps = {
    title: t(TK.switchToManualReview),
    self: {
      open: zusDialog.match('manualReviewSwitchDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'md',

    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
