import React from 'react';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../features/common/dialogs/layout/contentLayout';
import { useTranslation } from '../../../../hooks';
import { GetListRes } from '../types/GetListRes';
import { TableRow } from '../types/TableRow';

interface IProps {
  rowData: TableRow;
}

const ApproveRejectDialogContent = ({ rowData }: IProps) => {
  const { programAgentId, amount, remarks, createdBy, creationTime } = rowData;

  const { t } = useTranslation('paRebateAdjustmentRequest');

  return (
    <div style={{ padding: '32px 0' }}>
      <Container>
        <Row>
          <RowHeader>{t('programAgentId')}</RowHeader>
          <RowContent>{programAgentId}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('amount')}</RowHeader>
          <RowContent>{amount}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('remarks')}</RowHeader>
          <RowContent>{remarks}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('createdBy')}</RowHeader>
          <RowContent>{createdBy}</RowContent>
        </Row>
        <Row>
          <RowHeader>{t('creationTime')}</RowHeader>
          <RowContent>{creationTime}</RowContent>
        </Row>
      </Container>
    </div>
  );
};

export default ApproveRejectDialogContent;
