import { ApiResult } from '../../../../api/types';
import { displayAmountWithCurrency } from '../../../../helper';
import { getRewardQualifiedRepaymentTransactionTypeDisplayValue } from '../../../../helper/getRewardQualifiedRepaymentTransactionTypeDisplayValue';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const createDate = toDisplayTime(row.creationTime);
      const lastModifiedTime = toDisplayTime(row.lastModifiedTime);

      const transactionType = getRewardQualifiedRepaymentTransactionTypeDisplayValue(
        row.transactionType,
        translate
      );

      const toDisplayAmount = displayAmountCurrying(0, 2)
      const toDisplayHkdAmount = (amount: string | undefined) =>
        displayAmountWithCurrency(toDisplayAmount(amount ?? ''), 'HKD')

      return {
        id: index,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.orderId]: row.orderId,
        [TableColumnKey.customerNumber]: row.customerNumber,
        [TableColumnKey.transactionType]: transactionType,
        [TableColumnKey.repaymentCurrency]: row.repaymentCurrency,
        [TableColumnKey.repaymentAmount]: toDisplayHkdAmount(row.repaymentAmount),
        [TableColumnKey.repaymentRate]: row.repaymentRate,
        [TableColumnKey.rewardAmount]: toDisplayHkdAmount(row.rewardAmount),
        [TableColumnKey.postBalance]: toDisplayHkdAmount(row.postBalance),
        [TableColumnKey.creationTime]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
