import { Box, styled } from '@mui/material';
import { displayAmountWithCurrency } from '../../../../helper';
import { displayAmountCurrying } from '../../../../utils';
import { useTranslation } from '../../../../hooks';

interface SummaryRowProps {
  programCount: number
  rewardAmountInBillingCurrencySum: string
}

const SummaryRow = ({
  programCount,
  rewardAmountInBillingCurrencySum
}: SummaryRowProps) => {
  const toDisplayAmount = displayAmountCurrying(0, 2);
  const amount = toDisplayAmount(rewardAmountInBillingCurrencySum);
  const { t } = useTranslation('rewardRecord');
  return (
    <Row>
      <RowItem title={`${t('totalProgram')}:`}> {programCount}</RowItem>
      <RowItem title={`${t('totalAmount')}:`}>
        <>
          {displayAmountWithCurrency(amount, 'HKD')}
        </>
      </RowItem>
    </Row>
  )
}

export default SummaryRow

const Row = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  marginTop: '24px',
  gap: '50px'
}))

const RowItem = ({ title, children }: { title: string; children: React.ReactNode }) => {
  return (
    <div
      style={{
        display: 'flex',
        marginBottom: '24px',
        fontSize: '12px'
      }}
    >
      <div
        style={{
          wordBreak: 'break-word',
          textAlign: 'left',
          color: '#798797',
          marginRight: '6px'
        }}
      >
        {`${title}`}
      </div>
      <div
        style={{
          color: '#262E40',
          fontWeight: '700',
          whiteSpace: 'pre-wrap'
        }}
      >{children}</div>
    </div>
  );
};