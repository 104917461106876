import { ApiResult } from '../../../../api/types';
import getRewardStatusTypeDisplayValue from '../../../../helper/getRewardStatusTypeDisplayValue';
import { getRewardTypeDisplayValue } from '../../../../helper/getRewardType';
import { useTranslation } from '../../../../hooks';
import { displayAmountCurrying, toDisplayMonth, toDisplayTime } from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';
interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { translate } = useTranslation();

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const createDate = toDisplayTime(row.creationTime);
      const lastModifiedTime = toDisplayTime(row.lastModifiedTime);
      const displayMonth = toDisplayMonth(row.month);
      const toDisplayAmount = displayAmountCurrying(0, 2);

      return {
        id: index,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.customerNumber]: row.customerNumber,
        [TableColumnKey.rewardBatchId]: row.rewardBatchId,
        [TableColumnKey.type]: getRewardTypeDisplayValue(row.type, translate),
        [TableColumnKey.rewardCurrency]: row.rewardCurrency,
        [TableColumnKey.status]: getRewardStatusTypeDisplayValue(row.status, translate),
        [TableColumnKey.rewardAmount]: toDisplayAmount(row.rewardAmount),
        [TableColumnKey.month]: displayMonth,
        [TableColumnKey.creationTime]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,
        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
