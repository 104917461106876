import { EnumRewardType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';
import { ApiResult } from '../../../../api/types';
import { useTranslation } from '../../../../hooks';
import {
  toDisplayTime,
  toDisplayDate,
  toDisplayMonth,
  displayAmountCurrying,
} from '../../../../utils';
import { GetListRes } from '../types/GetListRes';
import { TableColumnKey } from '../types/TableColumnKeys';
import { TableRow } from '../types/TableRow';
import { getRewardTypeDisplayValue } from '../../../../helper/getRewardType';
import { displayAmountWithCurrency } from '../../../../helper';

interface UseTableRowsProps {
  apiList: ApiResult<GetListRes>;
}

type ConvertToTableRowsType = (apiList: ApiResult<GetListRes>) => TableRow[];

const useTableRows = (props: UseTableRowsProps) => {
  const { apiList } = props;
  const { t, translate } = useTranslation('rewardRecord');

  const convertToTableRows: ConvertToTableRowsType = (apiList) => {
    if (!apiList || !apiList?.rows) return [];

    const tableRows = apiList.rows.map((row, index): TableRow => {
      const createDate = toDisplayTime(row.creationTime);
      const lastModifiedTime = toDisplayTime(row.lastModifiedTime);
      const paymentDueDate = toDisplayDate(row.paymentDueDate);
      const displayMonth = toDisplayMonth(row.month);
      const rewardType = getRewardTypeDisplayValue(row.type, translate);
      const toDisplayAmount = displayAmountCurrying(0, 2);
      const toDisplayHKDAmount = (amount: string) =>
        displayAmountWithCurrency(toDisplayAmount(amount), t('hkd'));

      return {
        id: index,
        [TableColumnKey.rewardOrderId]: row.rewardOrderId,
        [TableColumnKey.programName]: row.programName,
        [TableColumnKey.customerNumber]: row.customerNumber,
        [TableColumnKey.month]: displayMonth,
        [TableColumnKey.type]: rewardType,
        [TableColumnKey.rewardBatchId]: row.rewardBatchId,
        [TableColumnKey.rewardCurrency]: row.rewardCurrency,
        [TableColumnKey.rewardAmount]: toDisplayAmount(row.rewardAmount),
        [TableColumnKey.paymentDueDate]: paymentDueDate,
        [TableColumnKey.qualifiedSpendingAmount]: toDisplayHKDAmount(row.qualifiedSpendingAmount),
        [TableColumnKey.qualifiedRepaymentAmount]: toDisplayHKDAmount(row.qualifiedRepaymentAmount),
        [TableColumnKey.rewardRate]: row.rewardRate,
        [TableColumnKey.exchangeRate]: row.exchangeRate,
        [TableColumnKey.rewardAmountInBillingCurrency]: toDisplayHKDAmount(
          row.rewardAmountInBillingCurrency
        ),
        [TableColumnKey.creationTime]: createDate,
        [TableColumnKey.lastModifiedTime]: lastModifiedTime,

        rawData: row,
      };
    });

    return tableRows;
  };

  const rows = convertToTableRows(apiList);

  return { rows, totalCounts: apiList.count, convertToTableRows };
};

export default useTableRows;
