import { TcspApplicationAnswer } from '../../../../types/GetListRes';
import SeniorPublicFigureDeclarationList from './SeniorPublicFigureDeclarationList';
import SeniorPublicFigureDeclarationSection from './SeniorPublicFigureDeclarationSection';

const PersonalInfo3 = ({ data }: { data: TcspApplicationAnswer }) => {
  const isHaveDeclaration = data.seniorPublicFigureDeclaration;

  return (
    <>
      <SeniorPublicFigureDeclarationSection isHaveDeclaration={isHaveDeclaration} />

      <SeniorPublicFigureDeclarationList
        list={data.seniorPublicFigureList}
        isHide={!isHaveDeclaration}
      />
    </>
  );
};

export default PersonalInfo3;
