export enum FilterFieldsKeys {
  CreatedDateFrom = 'createdDateFrom',
  CreatedDateTo = 'createdDateTo',
  LastModifiedTimeFrom = 'lastModifiedTimeFrom',
  LastModifiedTimeTo = 'lastModifiedTimeTo',
  ProgramNames = 'programNames',
  Currency = 'currency',
}

export interface FilterFields {
  [FilterFieldsKeys.CreatedDateFrom]: string;
  [FilterFieldsKeys.CreatedDateTo]: string;
  [FilterFieldsKeys.LastModifiedTimeFrom]: string;
  [FilterFieldsKeys.LastModifiedTimeTo]: string;
  [FilterFieldsKeys.ProgramNames]: string[];
  [FilterFieldsKeys.Currency]: string;
}
