import React, { useEffect, useState } from 'react';
import { Admin, CustomRoutes, localStorageStore, Resource } from 'react-admin';
import { Route } from 'react-router-dom';

import { ensureAccessToken } from './api/axiosInstance';
import i18nProvider from './components/i18nProvider';
import { LayoutBar } from './components/Layout';
import LoadingDialogProvider from './components/Layout/LoadingDialogProvider';
import { Box } from './components/MuiGenerals';
import { useRoutePermission } from './hooks';
import { useAppDispatch, useAppSelector } from './reducer/hooks';
import { selectProfile } from './reducer/profileSlice';
import { selectStuff, setIsRefreshingToken } from './reducer/stuffSlice';
import { COLOR_THEME } from './style/colorTheme';
import { queryClient } from './utils/config';
import { versionNumber } from './utils/constant';
import { theme } from './utils/theme';
import NoPermissionElement from './views/Auth/NoPermissionElement';
import { ExchangeRate, InjectionFeeConfiguration, ReferralCode } from './views/CobrandManagement';
import ExchangeRateConfiguration from './views/CobrandManagement/ExchangeRateConfiguration';
import Program from './views/CobrandManagement/Program';
import RewardRateConfiguration from './views/CobrandManagement/RewardRateConfiguration';
import {
  CardBlockRequest,
  CustomerAccount,
  CustomerApplication,
  CustomerList,
} from './views/CustomerManagement';
import Dashboard from './views/Dashboard';
import Membership from './views/PriorityPass/Membership';
import PaRebateAdjustmentRequest from './views/Reward/PaRebateAdjustmentRequest';
import QualifiedRepayment from './views/Reward/QualifiedRepayment';
import QualifiedRepaymentTransaction from './views/Reward/QualifiedRepaymentTransaction';
import RebateWalletBalance from './views/Reward/RebateWalletBalance';
import RedeemHistory from './views/Reward/RedeemHistory';
import RewardBatch from './views/Reward/RewardBatch';
import RewardRecord from './views/Reward/RewardRecord';
import Statement from './views/Reward/Statement';
import AssetCustodyWallet from './views/TCSP/AssetCustodyWallet';
import TcspAccount from './views/TCSP/TcspAccount';
import TcspAccountApplication from './views/TCSP/TcspAccountApplication';
import {
  AssetCustody,
  CreditAdjustmentRequest,
  RepaymentBatch,
  RepaymentSchedule,
  TcspTransactions,
} from './views/Transaction';
import AccountSetting from './views/User/AccountSetting';

//###add-new-page
const rawList = {
  accountSetting: <AccountSetting />,
  customerList: <CustomerList />,
  customerAccount: <CustomerAccount />,
  customerApplication: <CustomerApplication />,
  creditAdjustmentRequest: <CreditAdjustmentRequest />,
  repaymentBatch: <RepaymentBatch />,
  repaymentSchedule: <RepaymentSchedule />,
  tcspAccount: <TcspAccount />,
  tcspAccountApplication: <TcspAccountApplication />,
  assetCustodyWallet: <AssetCustodyWallet />,
  assetCustody: <AssetCustody />,
  tcspTransaction: <TcspTransactions />,
  exchangeRateConfiguration: <ExchangeRateConfiguration />,
  referralCode: <ReferralCode />,
  exchangeRate: <ExchangeRate />,
  program: <Program />,
  cardBlockRequest: <CardBlockRequest />,
  statement: <Statement />,
  rewardBatch: <RewardBatch />,
  rewardRecord: <RewardRecord />,
  qualifiedRepaymentTransaction: <QualifiedRepaymentTransaction />,
  qualifiedRepayment: <QualifiedRepayment />,
  redeemHistory: <RedeemHistory />,
  rebateWalletBalance: <RebateWalletBalance />,
  paRebateAdjustmentRequest: <PaRebateAdjustmentRequest />,
  rewardRateConfiguration: <RewardRateConfiguration />,
  membership: <Membership />,
  injectionFeeConfiguration: <InjectionFeeConfiguration />
};

const App = () => {
  useEffect(() => console.log('versionNumber', versionNumber), []);

  const dispatch = useAppDispatch();
  const { hasRoutePermission } = useRoutePermission();
  const { timezone } = useAppSelector(selectProfile);
  const { isRefreshingToken } = useAppSelector(selectStuff);

  // prevent isRefreshingToken dead lock
  useEffect(() => {
    const TIMEOUT_SECOND = 10 * 1000;

    let timeoutId: ReturnType<typeof setTimeout>;

    if (isRefreshingToken) {
      timeoutId = setTimeout(() => {
        dispatch(setIsRefreshingToken(false));
      }, TIMEOUT_SECOND);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [isRefreshingToken]);

  const list: { [key: string]: any } =
    Object.entries(rawList).map(([key, comp]) => {
      const element = hasRoutePermission(key) ? comp : <NoPermissionElement />;
      return [key, element];
    }) || {};

  const isTokenReady = useToken();

  if (!isTokenReady) return <></>;

  return (
    <Box
      sx={{
        main: {
          backgroundColor: COLOR_THEME.Page.Background,
          color: COLOR_THEME.Page.Text,
        },
      }}
    >
      <LoadingDialogProvider isLoadingDialogOpen={isRefreshingToken} />
      <Admin
        theme={theme}
        store={localStorageStore('0.1')}
        i18nProvider={i18nProvider}
        dashboard={Dashboard}
        queryClient={queryClient}
        layout={LayoutBar}
      >
        <Resource name="notExist" />
        <CustomRoutes>
          <Route>
            {list.map(([path, element]: [string, JSX.Element], i: number) => (
              <Route key={i} path={path} element={React.cloneElement(element, { timezone })} />
            ))}
          </Route>
        </CustomRoutes>
      </Admin>
    </Box>
  );
};
function useToken() {
  const [isReady, setIsReady] = useState(false);
  useEffect(() => {
    async function fetch() {
      const isTokenValid = await ensureAccessToken();
      if (isTokenValid) {
        setIsReady(true);
      }
    }
    fetch();
  }, []);

  return isReady;
}
export default App;
