import { GridRenderCellParams } from '@mui/x-data-grid';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { TableColumnKey } from '../types/TableColumnKeys';
import { usePermission } from '../../../../../hooks';
import { useGenGridCol } from '../../../../../utils/ComponentHelper';
import { COLUMN_WIDTH } from '../../../../../constants/ColumnWidth';

const useTableColumns = ({
  renderOperationsCell,
}: {
  renderOperationsCell: (params: GridRenderCellParams) => React.ReactNode;
}) => {
  const { hasPermission } = usePermission();
  const shouldHideOperationColumn = !hasPermission(
    FeatureCode.Reward.RebateWalletBalance.HistoryTab.prefix
  );

  const columns = [
    useGenGridCol(TableColumnKey.operation, {
      renderCell: renderOperationsCell,
      hide: shouldHideOperationColumn,
    }),
    useGenGridCol(TableColumnKey.programAgentId, {}),
    useGenGridCol(TableColumnKey.currency, {}),
    useGenGridCol(TableColumnKey.balance, {}),
    useGenGridCol(TableColumnKey.lastTransactionId, {}),
    useGenGridCol(TableColumnKey.createdDate, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.lastModifiedTime, {
      minWidth: COLUMN_WIDTH.LastModifiedDate.MinWidth,
    }),
  ];

  return columns;
};

export default useTableColumns;
