import { DialogInOne } from '../../../components';
import { useTranslation } from '../../../hooks';
import { ApproveHistory } from '../types/ApproveHistory';
import ApprovalProgressDialogContent from './ApprovalProgressDialogContent';

interface ApprovalProgressDialogProps {
  isOpen: boolean;
  closeDialog: () => void;
  approveHistory: ApproveHistory[];
  title?: string;
  content?: React.ReactNode;
}

const ApprovalProgressDialog = (props: ApprovalProgressDialogProps) => {
  const { title, isOpen, closeDialog, approveHistory } = props;
  const { tc } = useTranslation();

  const content = props.content || (
    <ApprovalProgressDialogContent approveHistory={approveHistory} />
  );

  const dialogConfig = {
    title: title || tc('approvalProgressDialogTitle'),
    self: {
      open: isOpen && !(!approveHistory || approveHistory.length === 0),
      onClose: () => {
        closeDialog();
      },
    },
    content: content,
    onConfirm: () => {},
    onCancel: () => {
      closeDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: 'md' as any,
  };

  return <DialogInOne {...dialogConfig} />;
};

export default ApprovalProgressDialog;
