import {
  EnumPaTransType,
  EnumRewardType,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';
import { SingleSelection } from '../../../../../components';
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';
import { Iprefix } from '../../../../../hooks/useTranslation';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;
  onClear: () => void;
}

const RebateWalletBalanceHistoryTransactionTypeSingleSelection = (props: IProps) => {
  const { label, value, onChange, onClear } = props;
  const { t, tc, translate } = useTranslation('rebateWalletBalance.historyTab' as Iprefix);

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: t('transactionType') })}
      value={value}
      onChange={onChange}
      enumData={EnumPaTransType}
      clearSelect={onClear}
      nameFn={(name) => translate(`paTransactionType.${name}`)}
      isNoSorting
    />
  );
};

export default RebateWalletBalanceHistoryTransactionTypeSingleSelection;
