import React, { SetStateAction } from 'react';

import { SingleSelection } from '../../../../../components';
import { useTranslation } from '../../../../../hooks';
import useChains from '../../../../../hooks/useChains';

interface IProps {
  value: any;
  onChange: (e: any) => void;
  onClearSelect: () => void;
  isDisabled?: boolean;
}

const PlusMinusSingleSelection = (props: IProps) => {
  const { value, onChange, onClearSelect, isDisabled } = props;

  const { tc } = useTranslation();

  const Enum = {
    '+': '+',
    '-': '-',
  };

  return (
    <SingleSelection
      label={tc('phSelection', { fieldName: tc('chainName') })}
      value={value}
      onChange={onChange}
      clearSelect={onClearSelect}
      enumData={Enum}
      nameFn={(item) => item}
      disabled={isDisabled}
    />
  );
};

export default PlusMinusSingleSelection;
