import { useEffect, useState } from 'react';

import DialogInOne, { IdialogInOneProps } from '../../../../../components/DialogInOne';
import { useAlerting, useTranslation } from '../../../../../hooks';
import { TableRow } from '../../types/TableRow';
import Content from './Content';
import useCreateRequestDialogValidation from './helpers/useCreateRequestDialogValidation';
import APIs from '../../../../../api';

type CreateRequestDialogProps = {
  isOpen: boolean;
  selectedRow: TableRow | undefined;
  closeDialog: () => void;
  refreshTable: () => void;
};

export interface ICreateRequestFields {
  programAgentId: string;
  transactionType: string;
  amount: string;
  amountPlusMinsSign: string;
  remarks: string;
}

const initField: ICreateRequestFields = {
  programAgentId: '',
  transactionType: '',
  amount: '',
  remarks: '',
  amountPlusMinsSign: '-',
};

export default function CreateRequestDialog({
  isOpen,
  selectedRow,
  closeDialog,
  refreshTable,
}: CreateRequestDialogProps) {
  const [fields, setFields] = useState<ICreateRequestFields>(initField);

  const { alerting } = useAlerting();

  const { tc, translate } = useTranslation();
  const { validate } = useCreateRequestDialogValidation({ fields });

  const handleConfirm = async () => {
    console.log(fields);

    const { allErrors } = validate();

    if (allErrors.length > 0) {
      alerting('warning', allErrors[0]);
      return;
    }

    const amountWithSign =
      fields.amountPlusMinsSign === '-'
        ? fields.amountPlusMinsSign.concat(fields.amount)
        : fields.amount;

    const res = await APIs.Reward.paRebateAdjustmentRequest.createRequest({
      programAgentId: fields.programAgentId,
      transactionType: Number(fields.transactionType),
      amount: amountWithSign,
      remarks: fields.remarks,
    });

    if (!res) return;

    alerting(
      'success',
      translate('paRebateAdjustmentRequest.createRequestDialog.createRequestSuccess')
    );
    closeDialog();
    refreshTable();
  };

  const dialogConfig: IdialogInOneProps = {
    title: translate('paRebateAdjustmentRequest.createRequestDialog.title'),
    self: {
      open: isOpen,
      onClose: closeDialog,
    },
    content: <Content fields={fields} setFields={setFields} />,
    onConfirm: handleConfirm,
    onCancel: closeDialog,
    size: 'sm',
  };

  return <DialogInOne {...dialogConfig} />;
}
