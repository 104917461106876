
import { Dispatch, SetStateAction, useEffect } from 'react'
import { DialogInOne } from '../../../../../components'
import { IdialogInOneProps } from '../../../../../components/DialogInOne'
import { GridBox } from '../../../../../components/Layout'
import ProgramNameSingleSelection from '../../../../common/filterTable/components/Filters/ProgramNameSingleSelection'
import useLocalTranslate from '../../helpers/useLocalTranslate'
import { initCreateRecordField } from '../../config'
import { CreateRecordFields } from '../../types/CreateRecordField'
import { TableColumnKey } from '../../types/TableColumnKey'
import CurrencySingleSelection from '../../../../common/filterTable/components/Filters/CurrencySingleSelection'
import LowerBoundAmountTextField from '../../../../common/filterTable/components/Filters/LowerBoundAmountTextField'
import FeeTypeSingleSelection from '../../../../common/filterTable/components/Filters/FeeTypeSingleSelection'
import FeeTextField from '../../../../common/filterTable/components/Filters/FeeTextField'
import useAssets from '../../../../../hooks/useAssets/useAssets'

interface Iprops {
  isOpen: boolean
  createRecordFields: CreateRecordFields
  closeDialog: () => void
  setCreateRecordFields: Dispatch<SetStateAction<CreateRecordFields>>
  handleCreateRecordSubmit: () => Promise<void>
}

const DialogCreateRecord = (props: Iprops) => {
  const {
    isOpen,
    createRecordFields,
    closeDialog,
    setCreateRecordFields,
    handleCreateRecordSubmit
  } = props;

  const { tc } = useLocalTranslate()

  const { getAssetDetailByAssetName } = useAssets({
    programName: createRecordFields.programName,
  });

  const updateField = (fieldName: string, value: any) => {
    setCreateRecordFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const handleCloseDialog = () => {
    closeDialog()
    setCreateRecordFields(initCreateRecordField)
  };

  const handleSubmit = async () => {
    handleCreateRecordSubmit()
  }


  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TableColumnKey.ProgramName,
      <ProgramNameSingleSelection
        value={createRecordFields[TableColumnKey.ProgramName]}
        onChange={(e) => {
          updateField(TableColumnKey.ProgramName, e.target.value)
        }}
        onClear={() => {
          updateField(TableColumnKey.ProgramName, '')
        }}
      />
    ],
    [
      TableColumnKey.Currency,
      <CurrencySingleSelection
        programName={createRecordFields[TableColumnKey.ProgramName]}
        value={createRecordFields[TableColumnKey.Currency]}
        onClick={(e) => {
          const value = (e.target as HTMLElement).dataset.value;
          updateField(TableColumnKey.Currency, value)
          updateField('custodyAmountDecimals', getAssetDetailByAssetName(value ?? '')?.decimals)
        }}
      />
    ],
    [
      TableColumnKey.FeeType,
      <FeeTypeSingleSelection
        value={createRecordFields[TableColumnKey.FeeType]}
        onChange={(e) => updateField(TableColumnKey.FeeType, e.target.value)}
      />
    ],
    [
      TableColumnKey.LowerBoundAmount,
      <LowerBoundAmountTextField
        value={createRecordFields[TableColumnKey.LowerBoundAmount]}
        onChange={(e) => updateField(TableColumnKey.LowerBoundAmount, e.target.value)}
      />
    ],
    [
      TableColumnKey.Fee,
      <FeeTextField
        value={createRecordFields[TableColumnKey.Fee]}
        feeType={createRecordFields[TableColumnKey.FeeType]}
        onChange={(e) => updateField(TableColumnKey.Fee, e.target.value)}
      />
    ],
  ]

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_record'),
    self: {
      open: isOpen,
      onClose: handleCloseDialog
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,
    size: 'sm',
    dialogActionStyling: { padding: '8px' },
  }
  return (
    <DialogInOne {...dialogConfig} />
  )
}

export default DialogCreateRecord