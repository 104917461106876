import { GridRenderCellParams } from '@mui/x-data-grid';
import { MerchantPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import { COLUMN_WIDTH } from '../../../../constants/ColumnWidth';
import { usePermission } from '../../../../hooks';
import { useGenGridCol } from '../../../../utils/ComponentHelper';
import { TableColumnKey } from '../types/TableColumnKeys';

interface IProps {
  renderApprovalProgressCell: (params: GridRenderCellParams) => React.ReactNode;
  renderOperationsCell: (params: GridRenderCellParams) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderApprovalProgressCell, renderOperationsCell } = props;
  const { hasPermission } = usePermission();

  const hasApprovePermission = hasPermission(
    MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Approve.prefix
  );
  const hasRejectPermission = hasPermission(
    MerchantPortalFeatureCode.Reward.PaRebateAdjustmentRequest.Reject
  );

  const columns = [
    useGenGridCol(TableColumnKey.Operations, {
      renderCell: renderOperationsCell,
      hide: !hasApprovePermission && !hasRejectPermission,
    }),
    useGenGridCol(TableColumnKey.ProgramAgentId),
    useGenGridCol(TableColumnKey.ApprovalProgress, {
      renderCell: renderApprovalProgressCell,
    }),
    useGenGridCol(TableColumnKey.Amount),
    useGenGridCol(TableColumnKey.Status),
    useGenGridCol(TableColumnKey.Remarks),
    useGenGridCol(TableColumnKey.CreatedBy),
    useGenGridCol(TableColumnKey.CreationTime),
    useGenGridCol(TableColumnKey.LastModifiedTime),
  ];

  return columns;
};

export default useTableColumns;
