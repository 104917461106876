import React, { ReactNode, SetStateAction } from 'react';

import CollapsibleFilters from '../../../../../features/common/filterTable/components/Filters/CollapsibleFilters';
import LastTransactionIdTextField from '../../../../../features/common/filterTable/components/Filters/LastTransactionIdTextField';
import ProgramAgentIdTextField from '../../../../../features/common/filterTable/components/Filters/ProgramAgentIdTextField';
import { Filter } from '../../../../../features/common/filterTable/types';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';

interface FilterSectionProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  onSearch: () => void;
  onReset: () => void;
  isCollapse?: boolean;
  DateObj?: any;
}

const FilterSection = (props: FilterSectionProps) => {
  const { fields, setFields, onSearch, onReset, isCollapse } = props;

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const filters: Filter[] = [
    {
      labelKey: FilterFieldsKeys.ProgramAgentId,
      filter: (
        <ProgramAgentIdTextField
          value={fields[FilterFieldsKeys.ProgramAgentId]}
          onChange={(e) => updateField(FilterFieldsKeys.ProgramAgentId, e.target.value)}
        />
      ),
    },
    {
      labelKey: FilterFieldsKeys.LastTransactionId,
      filter: (
        <LastTransactionIdTextField
          value={fields[FilterFieldsKeys.LastTransactionId]}
          onChange={(e) => updateField(FilterFieldsKeys.LastTransactionId, e.target.value)}
        />
      ),
    },
  ];

  return (
    <>
      <CollapsibleFilters
        isCollapse={isCollapse}
        filters={filters}
        onSearch={onSearch}
        onReset={onReset}
      />
    </>
  );
};

export default FilterSection;
