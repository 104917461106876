import { useMemo } from 'react';

import { styled } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { EnumApprovalStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { CustomPagination, NoRowsOverlay } from '../../../components/Layout';
import { useTranslation } from '../../../hooks';
import { toDisplayTime } from '../../../utils';
import { useGenGridCol } from '../../../utils/ComponentHelper';
import { dataGridDefaults } from '../../../utils/constant';
import { ApproveHistory } from '../types/ApproveHistory';

interface ApprovalProgressDialogContentProps {
  approveHistory: ApproveHistory[];
  isShowPendingAfterRejected?: boolean;
}

const StyledDataGrid = styled(DataGrid)(
  () => `
  .MuiDataGrid-columnHeaders {
    background: #ECEEF9;
  }

  `
);

const ApprovalProgressDialogContent = (props: ApprovalProgressDialogContentProps) => {
  const { t } = useTranslation('enumConstants');

  const columns = [
    useGenGridCol('approval_sequence'),
    useGenGridCol('permission'),
    useGenGridCol('operation_result'),
    useGenGridCol('operated_by'),
    useGenGridCol('operation_time'),
    useGenGridCol('remarks'),
  ];

  const createTableRows = (data: ApproveHistory[]) => {
    if (!data || data?.length === 0) {
      return [];
    }

    return data.map((item) => {
      const operationTime = item.approvalDate || item.approvedDate;
      const remarks = item.remark || item.remarks;

      return {
        id: item.seq,
        approval_sequence: item.seq,
        permission: item.approvalPermission,
        operation_result: t(EnumApprovalStatus[item.status]),
        operated_by: item.approvedBy,
        operation_time: toDisplayTime(operationTime),
        remarks: remarks,
      };
    });
  };

  const { approveHistory, isShowPendingAfterRejected } = props;

  const displayApprovalArray = useMemo(() => {
    if (isShowPendingAfterRejected) {
      return approveHistory;
    }

    const filteredApprovalArr = approveHistory?.filter(({ status }) => {
      if (approveHistory.find(({ status }) => status === EnumApprovalStatus.Rejected)) {
        return status !== EnumApprovalStatus.Pending;
      }
      return true;
    });

    return filteredApprovalArr;
  }, [approveHistory, isShowPendingAfterRejected]);

  const rows = createTableRows(displayApprovalArray);

  return (
    <div style={{ padding: '8px 32px 24px', background: '#ECEEF9' }}>
      <StyledDataGrid
        {...dataGridDefaults}
        rows={rows}
        rowCount={displayApprovalArray?.length}
        columns={columns}
        page={0}
        components={{
          NoRowsOverlay,
          Footer: CustomPagination,
        }}
        componentsProps={{
          footer: { totalRecords: displayApprovalArray?.length, footerBgColor: '#ECEEF9' },
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'approval_sequence', sort: 'asc' }],
          },
        }}
        hideFooter={true}
      />
    </div>
  );
};

export default ApprovalProgressDialogContent;
