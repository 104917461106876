import { ReactNode, useState } from 'react';

import { EnumApprovalStatus } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import { DialogInOne } from '../../../components';
import { Box } from '../../../components/MuiGenerals';
import { useAlerting, usePermission, useTranslation } from '../../../hooks';
import { ApproveHistory } from '../types/ApproveHistory';
import { IActionsProps, ICustomErrorMsg, IDataProps } from '../types/ApproveRejectDialog';
import ActionButtons from './ActionButtons';
import ApprovalProgressDialogContent from './ApprovalProgressDialogContent';
import SubTitleRow from './DetailView/SubTitleRow';

interface IProps {
  title?: string;
  isOpen: boolean;
  closeDialog: () => void;
  refreshTable: () => void;
  data: IDataProps;
  actions: IActionsProps;
  customErrorMsg?: ICustomErrorMsg;
  children?: ReactNode;
}

const ApproveRejectDialog = (props: IProps) => {
  const { isOpen, closeDialog, data, refreshTable, children, actions, customErrorMsg } = props;
  const {
    createdBy,
    operator,
    approvalsRequired,
    approvalsCount,
    approveHistory,
    applicationNumber,
    customerNumber,
    isHideSubTitleRow,
  } = data;

  const [remarksInput, setRemarksInput] = useState<string>('');

  const { tc } = useTranslation();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();

  const getFirstApprover = () => {
    if (!approveHistory || approveHistory?.length === 0) return null;

    const sortedApprovedHistory = approveHistory
      .filter((item) => {
        return item.status === EnumApprovalStatus.Approved;
      })
      .sort((a, b) => a.seq - b.seq);

    return sortedApprovedHistory?.[0]?.approvedBy;
  };

  const isLastApprover = approvalsRequired - approvalsCount === 1;
  const isSameOperator = createdBy === operator;
  const firstApprover = getFirstApprover();
  const isSameApprover = firstApprover === operator;
  const isShowApproveHistory = approveHistory?.length > 0;

  const hasApprovePermission = hasPermission(actions.approve.permission);

  const hasRejectPermission = hasPermission(actions.reject.permission);

  const closeDialogAndRefresh = () => {
    closeDialog();
    refreshTable();
  };

  const checkIsCanApproveProceed = () => {
    if (!hasApprovePermission) {
      alerting('error', tc('no_relevant_permission'));
      return false;
    }

    if (isSameOperator) {
      const errorMsg = customErrorMsg?.sameOperator || tc('no_same_operator_creator');
      alerting('error', errorMsg);
      return false;
    }

    if (isSameApprover) {
      const errorMsg = customErrorMsg?.sameApprover || tc('no_same_approver');
      alerting('error', errorMsg);
      return false;
    }

    return true;
  };

  const selectApprovalLevel = () => {
    if (!approveHistory || approveHistory?.length === 0) return null;

    const sortedPendingApprovals = approveHistory
      .filter((item) => item.status === EnumApprovalStatus.Pending)
      .sort((a, b) => {
        return a.seq - b.seq;
      });

    return sortedPendingApprovals[0];
  };

  const handleApproveClick = async () => {
    const isCanProceed = checkIsCanApproveProceed();

    if (!isCanProceed) return;

    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    if (!hasPermission(approvalLevel.approvalPermission)) {
      return alerting('error', tc('no_relevant_permission'));
    }

    const res = await actions.approve.apiFunc({
      id: approvalLevel.id,
      remarks: remarksInput,
    });

    if (!res) return;

    const msg = isLastApprover ? tc('request_approved') : tc('approve_successful');

    alerting('success', msg);
    closeDialogAndRefresh();
  };

  const handleRejectClick = async () => {
    if (!hasRejectPermission) {
      return alerting('error', tc('no_relevant_permission'));
    }

    const approvalLevel = selectApprovalLevel();

    if (!approvalLevel) return;

    const res = await actions.reject.apiFunc({
      id: approvalLevel.id,
      remarks: remarksInput,
    });

    if (!res) return;

    alerting('success', tc('request_rejected'));
    closeDialogAndRefresh();
  };

  const handleRemarksChange = (e: any) => {
    setRemarksInput(e.target.value);
  };

  if (!approveHistory || approveHistory?.length === 0) return <></>;

  const dialogConfig = {
    title: props.title || tc('approveReject'),
    self: {
      open: isOpen,
      onClose: () => {
        closeDialog();
      },
    },
    onConfirm: () => {},
    onCancel: () => {
      closeDialog();
    },
    isLoadingDialog: true,
    isConfirmHidden: true,
    size: 'xl' as any,
    isFullScreen: true,
    isCancelHidden: true,
    isShowCrossButton: true,
    content: (
      <div style={{ marginBottom: '24px' }}>
        {isShowApproveHistory && (
          <Box style={{ marginTop: '62px' }}>
            <ApprovalProgressDialogContent approveHistory={props.data.approveHistory} />
          </Box>
        )}

        {!isHideSubTitleRow && (
          <SubTitleRow applicationNumber={applicationNumber} customerNumber={customerNumber} />
        )}

        {children && <Box>{children}</Box>}
      </div>
    ),
    actionButtons: (
      <ActionButtons
        handleApproveClick={handleApproveClick}
        handleRejectClick={handleRejectClick}
        closeDialog={closeDialog}
        remarksInput={remarksInput}
        handleRemarksChange={handleRemarksChange}
      />
    ),
  };

  return <DialogInOne {...dialogConfig}></DialogInOne>;
};

export default ApproveRejectDialog;
