import { EnumInjectionFeeAmountType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum";


export const getInjectionFeeConfigurationFeeAmountType = (
  type: number,
  translateFunc: (key: string) => string
) => {
  const value = EnumInjectionFeeAmountType[type];

  if (!value) return '';

  return translateFunc(`enumConstants.${value}`);
};
