import { ReactNode } from 'react';

import { Box } from '../../../../components/MuiGenerals';
import { customSx } from '../../../../utils/styling';

const FilterTableLayoutContainer = ({
  children,
  isCollapse,
  style,
}: {
  children: ReactNode;
  isCollapse?: boolean;
  style?: any;
}) => {
  return (
    <Box
      sx={{
        ...customSx.layoutBox,
        paddingTop: isCollapse ? '12px' : '2rem',
        ...style,
      }}
    >
      {children}
    </Box>
  );
};

export default FilterTableLayoutContainer;
