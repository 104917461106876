import { EnumInjectionFeeAmountType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum';
import MpTextFieldWithEndAdornment from '../../../../../components/TextField/MpTextFieldWithEndAdornment';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  feeType?: string;
  onChange: (e: any) => void;
}

const FeeTextField = (props: IProps) => {
  const { label, value, feeType, onChange } = props;

  const { tc } = useTranslation();

  return (
    <MpTextFieldWithEndAdornment
      mode={'number'}
      label={label || tc('phInputField', { fieldName: tc('fee') })}
      value={value}
      onChange={onChange}
      endAdornmentNode={'%'}
      isHideAdornment={feeType !== EnumInjectionFeeAmountType.Percentage.toString()}
      maxNumber={feeType === EnumInjectionFeeAmountType.Percentage.toString() ? 100 : undefined}
      decimalCount={4}
    />
  );
};

export default FeeTextField;
