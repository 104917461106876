
import { EnumInjectionFeeAmountType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum";
import { CreateRecordFields } from "./types/CreateRecordField";
import { FilterFieldsKeys } from "./types/FilterFields";
import { TableColumnKey } from "./types/TableColumnKey";

const initFields = {
  [FilterFieldsKeys.CreatedDateFrom]: '',
  [FilterFieldsKeys.CreatedDateTo]: '',
  [FilterFieldsKeys.LastModifiedTimeFrom]: '',
  [FilterFieldsKeys.LastModifiedTimeTo]: '',
  [FilterFieldsKeys.ProgramNames]: [],
  [FilterFieldsKeys.Currency]: '',
};

const initCreateRecordField: CreateRecordFields = {
  [TableColumnKey.ProgramName]: '',
  [TableColumnKey.Currency]: '',
  [TableColumnKey.FeeType]: EnumInjectionFeeAmountType.Percentage.toString(),
  [TableColumnKey.LowerBoundAmount]: '',
  [TableColumnKey.Fee]: '',
  custodyAmountDecimals: ''
}

export {
  initFields,
  initCreateRecordField
}