import React, { useEffect, useState } from 'react';
import PermissionTab from '../../../components/Layout/PermissionTab';
import { useTranslation } from '../../../hooks';
import { MerchantPortalFeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import BalanceTab from './BalanceTab';
import HistoryTab from './HistoryTab';

const RebateWalletBalance = () => {
  const { t } = useTranslation('rebateWalletBalance');

  const [forceSelectedTab, setForceSelectedTab] = useState<string | undefined>('');
  const [programAgentIdForSearch, setProgramAgentIdForSearch] = useState<string>('');

  const redirectToHistoryTab = (programAgentId: string) => {
    setForceSelectedTab(t(`historyTab.title`));
    setProgramAgentIdForSearch(programAgentId);
  };

  useEffect(() => {
    if (!forceSelectedTab) {
      return;
    }

    setForceSelectedTab(undefined);
  }, [forceSelectedTab]);

  return (
    <PermissionTab
      contents={[
        {
          element: (
            <BalanceTab
              redirectToHistoryTab={redirectToHistoryTab}
              setProgramAgentIdForSearch={setProgramAgentIdForSearch}
            />
          ),
          name: t(`balanceTab.title`),
          permissionCode: MerchantPortalFeatureCode.Reward.RebateWalletBalance.BalanceTab.prefix,
        },
        {
          element: <HistoryTab programAgentIdForSearch={programAgentIdForSearch} />,
          name: t(`historyTab.title`),
          permissionCode: MerchantPortalFeatureCode.Reward.RebateWalletBalance.HistoryTab.prefix,
        },
      ]}
      forceSelectTab={forceSelectedTab}
    />
  );
};

export default RebateWalletBalance;
