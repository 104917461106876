import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';

import APIs from '../../../../api';
import { ApiResult } from '../../../../api/types';
import { ExportBtn } from '../../../../components/Button';
import { useDatePicker } from '../../../../components/DatePicker';
import { DivideLine } from '../../../../components/Layout';
import ToggleFilterButton from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../../features/common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import Table from '../../../../features/common/filterTable/components/Table';
import { convertToExportRows } from '../../../../features/common/filterTable/helpers/convertToExportRows';
import FilterContainer from '../../../../features/common/filterTable/layout/FilterContainer';
import FilterSectionActionRow from '../../../../features/common/filterTable/layout/FilterSectionActionRow';
import FilterTableLayoutContainer from '../../../../features/common/filterTable/layout/FilterTableLayoutContainer';
import TableContainer from '../../../../features/common/filterTable/layout/TableContainer';
import { useAlerting, usePermission, useTabs, useTranslation } from '../../../../hooks';
import { Iprefix } from '../../../../hooks/useTranslation';
import { downloadFiles, getFullApiResponse } from '../../../../utils';
import { useZusDialogStore, useZusTranslatePrefixStore } from '../../../../zustand/store';
import FilterSection from './components/FilterSection';
import useGetListParams from './helpers/useGetListParams';
import useTableColumns from './helpers/useTableColumns';
import useTableRows from './helpers/useTableRows';
import { FilterFields, FilterFieldsKeys } from './types/FilterFields';
import { GetListRes } from './types/GetListRes';

const initFields = {
  [FilterFieldsKeys.createdDateFrom]: '',
  [FilterFieldsKeys.createdDateTo]: '',
  [FilterFieldsKeys.lastModifiedTimeFrom]: '',
  [FilterFieldsKeys.lastModifiedTimeTo]: '',
  [FilterFieldsKeys.ProgramAgentId]: '',
  [FilterFieldsKeys.ProgramName]: [],
  [FilterFieldsKeys.TransactionType]: '',
};

const translatePrefix = 'rebateWalletBalance.historyTab' as Iprefix;

interface IProps {
  programAgentIdForSearch: string;
}

const HistoryTab = ({ programAgentIdForSearch }: IProps) => {
  const { getListParams } = useGetListParams();

  const [page, setPage] = useState(0);
  const [fields, setFields] = useState<FilterFields>(initFields);
  const [listParams, setListParams] = useState(getListParams(fields));
  const [list, setList] = useState<ApiResult<GetListRes>>({ rows: [], count: 0 });

  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();

  const columns = useTableColumns();
  const { rows, totalCounts, convertToTableRows } = useTableRows({ apiList: list });

  const zusDialog = useZusDialogStore();
  const { alerting } = useAlerting();
  const { hasPermission } = usePermission();
  const { t, tc } = useTranslation(translatePrefix);
  const { setTranslatePrefix } = useZusTranslatePrefixStore();
  const { Tabs } = useTabs([{ name: tc('table'), value: '' }]);

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const isShowExportBtn = hasPermission(FeatureCode.Reward.RebateWalletBalance.HistoryTab.Export);

  const onExport = async () => {
    const params = getListParams(fields);
    const { currentTime, ...cleanParams } = params;

    if (totalCounts === 0) {
      return alerting('error', tc('no_data_export'));
    }

    const apiFn = (page: number, pageSize: number, signal: any) =>
      APIs.Reward.rebateWalletBalance.historyTabGetExport(
        { ...cleanParams, page, pageSize },
        { signal }
      );

    const rawRes = await getFullApiResponse(apiFn, totalCounts, true);

    if (rawRes.length === 0) return;

    const omitKeys = ['id', 'rawData'];
    const tableRows = convertToTableRows({ rows: rawRes } as ApiResult<GetListRes>);
    const exportRows = convertToExportRows({ data: tableRows, omitKeys, translateFunc: t });

    downloadFiles(`Rebate Wallet Balance History`, exportRows, {});
  };

  const onPageChange = (page: number) => {
    setPage(page);
    setListParams({ ...listParams, page });
  };

  const onFilterSearch = () => {
    setPage(0);
    setListParams({
      ...getListParams(fields),
      page: 0,
      currentTime: Date.now(),
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  // const refreshTable = () => {
  //   setListParams({ ...listParams, currentTime: Date.now() });
  // };

  const handleSettled = () => zusDialog.closeExtra();

  const handleSuccess = (data: ApiResult<GetListRes>) => {
    if (!data) return;
    setList(data);
  };

  const handleQuery = () => {
    zusDialog.openExtra('loadingDialog');
    const { currentTime, ...cleanParams } = listParams;
    return APIs.Reward.rebateWalletBalance.historyTabGetAll(cleanParams);
  };

  useQuery(['getRebateWalletBalanceHistory', listParams], handleQuery, {
    onSettled: handleSettled,
    onSuccess: handleSuccess,
  });

  useEffect(() => {
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.ProgramAgentId]: programAgentIdForSearch,
      };
    });

    setListParams((fields: any) => {
      return {
        ...fields,
        [FilterFieldsKeys.ProgramAgentId]: programAgentIdForSearch,
      };
    });
  }, [programAgentIdForSearch]);

  useEffect(() => setTranslatePrefix(translatePrefix), []);

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.createdDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.createdDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.lastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.lastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);

  return (
    <FilterTableLayoutContainer style={{ padding: '0', paddingTop: '0' }}>
      <FilterContainer>
        <FilterSectionActionRow>
          <ToggleFilterButton isCollapse={isCollapse} onClick={toggleFilterCollapse} />
        </FilterSectionActionRow>

        <FilterSection
          fields={fields}
          setFields={setFields}
          onSearch={onFilterSearch}
          onReset={onFilterReset}
          isCollapse={isCollapse}
          DateObj={DateObj}
        />
      </FilterContainer>

      <DivideLine />

      <TableContainer>
        <ExportBtn onExport={onExport} isShow={isShowExportBtn} />
        <Tabs>
          <Table
            columns={columns}
            rows={rows}
            rowCount={totalCounts}
            page={page}
            onPageChange={onPageChange}
          />
        </Tabs>
      </TableContainer>
    </FilterTableLayoutContainer>
  );
};

export default HistoryTab;
