import React, { SetStateAction, useEffect } from 'react';
import { FilterFields, FilterFieldsKeys } from '../types/FilterFields';
import { Filter } from '../../../common/filterTable/types';
import CollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters';
import FilterContainer from '../../../common/filterTable/layout/FilterContainer';
import FilterSectionActionRow from '../../../common/filterTable/layout/FilterSectionActionRow';
import ToggleFilterButton from '../../../common/filterTable/components/Filters/CollapsibleFilters/components/ToggleFilterButton';
import useCollapsibleFilters from '../../../common/filterTable/components/Filters/CollapsibleFilters/hooks/useCollapsibleFilters';
import { useDatePicker } from '../../../../components/DatePicker';
import { initFields } from '../config';
import FilterBarCreateRecordButton from '../../../../components/Button/FilterBarCreateRecordButton';
import { usePermission } from '../../../../hooks';
import { MerchantPortalFeatureCode as FeatureCode } from '@wallet-manager/pfh-pmp-node-def-types';
import { TableColumnKey } from '../types/TableColumnKey';
import CurrencySingleSelection from '../../../common/filterTable/components/Filters/CurrencySingleSelection';
import ProgramNameMultipleSelection from '../../../common/filterTable/components/Filters/ProgramNameMultipleSelection';

interface ViewFilterProps {
  fields: FilterFields;
  setFields: React.Dispatch<SetStateAction<FilterFields>>;
  setIsDialogCreateOpen: () => void;
  onSearch: () => void;
}

const ViewFilter = (props: ViewFilterProps) => {
  const { fields, setFields, onSearch, setIsDialogCreateOpen } = props;
  const { isCollapse, toggleFilterCollapse } = useCollapsibleFilters();


  const { hasPermission } = usePermission()
  const isShowCreateRecordBtn = hasPermission(FeatureCode.CobrandManagement.InjectionFeeConfiguration.CreateRecord)

  const DateObj = {
    CreationTime: useDatePicker(),
    LastModifiedTime: useDatePicker(),
  };

  const updateField = (fieldName: string, value: any) => {
    setFields((fields) => {
      return {
        ...fields,
        [fieldName]: value,
      };
    });
  };

  const onFilterReset = () => {
    setFields(initFields);
    DateObj.CreationTime.clearDate();
    DateObj.LastModifiedTime.clearDate();
  };

  useEffect(() => {
    // sync fields state with date picker
    setFields((fields) => {
      return {
        ...fields,
        [FilterFieldsKeys.CreatedDateFrom]: DateObj.CreationTime.start,
        [FilterFieldsKeys.CreatedDateTo]: DateObj.CreationTime.end,
        [FilterFieldsKeys.LastModifiedTimeFrom]: DateObj.LastModifiedTime.start,
        [FilterFieldsKeys.LastModifiedTimeTo]: DateObj.LastModifiedTime.end,
      };
    });
  }, [
    DateObj.CreationTime.start,
    DateObj.CreationTime.end,
    DateObj.LastModifiedTime.start,
    DateObj.LastModifiedTime.end,
  ]);


  const filters: Filter[] = [
    {
      labelKey: TableColumnKey.CreationTime,
      filter: <DateObj.CreationTime.Picker type="dateTime" />
    },
    {
      labelKey: TableColumnKey.LastModifiedTime,
      filter: <DateObj.LastModifiedTime.Picker type="dateTime" />
    },
    {
      labelKey: TableColumnKey.ProgramName,
      filter: <ProgramNameMultipleSelection
        value={fields[FilterFieldsKeys.ProgramNames]}
        onClear={() => {
          setFields((fields) => ({
            ...fields,
            [FilterFieldsKeys.ProgramNames]: [],
          }))
        }}
        onChange={(value: string[]) => {
          setFields((fields) => ({
            ...fields,
            [FilterFieldsKeys.ProgramNames]: value,
          }));
        }}
      />
    },
    {
      labelKey: TableColumnKey.Currency,
      filter: <CurrencySingleSelection
        programName={fields[FilterFieldsKeys.ProgramNames]}
        value={fields[FilterFieldsKeys.Currency]}
        onClick={(e) => updateField(FilterFieldsKeys.Currency, (e.target as HTMLElement).dataset.value)}
      />
    }
  ];

  return (
    <>
      <FilterSectionActionRow>
        <FilterBarCreateRecordButton
          isShow={isShowCreateRecordBtn}
          onClick={() => setIsDialogCreateOpen()}
        />
        <ToggleFilterButton
          isCollapse={isCollapse}
          onClick={toggleFilterCollapse}
        />
      </FilterSectionActionRow>
      <FilterContainer>
        <CollapsibleFilters
          filters={filters}
          isCollapse={isCollapse}
          onSearch={onSearch}
          onReset={onFilterReset}
        />
      </FilterContainer>
    </>
  );
};

export default ViewFilter;
