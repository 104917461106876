import { EnumRewardType } from "@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward"

export const getRewardTypeDisplayValue = (
  type: number,
  translateFunc: (key: string) => string
) => {
  const value = EnumRewardType[type];

  if (!value) return '';

  return translateFunc(`enumConstants.${value}`)

}