import { useTranslation } from '../../../../../../../hooks';
import Column from '../../../../../../approval/components/DetailView/Column';
import RowItem from '../../../../../../approval/components/DetailView/RowItem';
import Section from '../../../../../../approval/components/DetailView/Section';
import { SeniorPublicFigureList } from '../../../../types/GetListRes';

interface IProps {
  list?: SeniorPublicFigureList[];
  isHide?: boolean;
}

const SeniorPublicFigureDeclarationList = ({ list, isHide }: IProps) => {
  const { t, tcc } = useTranslation('tcspAccountApplication');

  if (isHide || !list || list.length === 0) return <></>;

  return (
    <>
      {list.map((item, index) => {
        return (
          <Section key={`senior-public-list-${index}`}>
            <Column withDivider>
              <div style={{ padding: '12px 0 24px 0' }}>{t('member', { index: index + 1 })}</div>

              <RowItem title={t('firstName')}>{item.firstName}</RowItem>
              <RowItem title={t('lastName')}>{item.lastName}</RowItem>
              <RowItem title={t('countryOfGov')}>{tcc(item.country)}</RowItem>
            </Column>

            <Column style={{ padding: '68px 40px 20px' }}>
              <RowItem title={t('department')}>{item.department}</RowItem>
              <RowItem title={t('position')}>{item.position}</RowItem>
              <RowItem title={t('relationshipWithCustomer')}>{item.relationship}</RowItem>
            </Column>
          </Section>
        );
      })}
    </>
  );
};

export default SeniorPublicFigureDeclarationList;
