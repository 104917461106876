import { EptReward } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/Endpoint/MerchantPortal';
import axios from '../axiosInstance';

async function balanceTabGetAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(EptReward.RebateWalletBalance.balanceTab.getAll, input);
}

async function balanceTabGetExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(EptReward.RebateWalletBalance.balanceTab.export, input);
}

async function historyTabGetAll(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(EptReward.RebateWalletBalance.historyTab.getAll, input);
}

async function historyTabGetExport(params: any, config?: any) {
  const { pageSize, page, ...rest } = params;
  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(EptReward.RebateWalletBalance.historyTab.export, input);
}

export default { balanceTabGetAll, balanceTabGetExport, historyTabGetAll, historyTabGetExport };
