import { GridRenderCellParams } from "@mui/x-data-grid";
import { COLUMN_WIDTH } from "../../../../constants/ColumnWidth";
import { useGenGridCol } from "../../../../utils/ComponentHelper";
import { TableColumnKey } from "../types/TableColumnKey";

interface IProps {
  renderOperationsCell: (params: GridRenderCellParams<any, any, any>) => React.ReactNode;
}

const useTableColumns = (props: IProps) => {
  const { renderOperationsCell } = props;

  const columns = [
    useGenGridCol(TableColumnKey.Operation, {
      renderCell: renderOperationsCell
    }),
    useGenGridCol(TableColumnKey.ProgramName),
    useGenGridCol(TableColumnKey.Currency),
    useGenGridCol(TableColumnKey.FeeType),
    useGenGridCol(TableColumnKey.LowerBoundAmount),
    useGenGridCol(TableColumnKey.Fee),
    useGenGridCol(TableColumnKey.CreatedBy),
    useGenGridCol(TableColumnKey.CreationTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    }),
    useGenGridCol(TableColumnKey.LastModifiedBy),
    useGenGridCol(TableColumnKey.LastModifiedTime, {
      minWidth: COLUMN_WIDTH.CreatedDate.MinWidth,
    })
  ]
  return { columns };
}
export default useTableColumns;