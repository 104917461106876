import { EnumInjectionFeeAmountType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/ApiEnum';
import { SingleSelection } from '../../../../../components'
import { SelectChangeEvent } from '../../../../../components/MuiGenerals';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: string;
  onChange: (e: SelectChangeEvent) => void;

}

const FeeTypeSingleSelection = (props: IProps) => {

  const { label, value, onChange, } = props;

  const { tc } = useTranslation();

  return (
    <SingleSelection
      label={label || tc('phSelection', { fieldName: tc('feeType') })}
      value={value}
      clearSelect={() => { }}
      onChange={onChange}
      enumData={EnumInjectionFeeAmountType}
      nameFn={(item) => item}
    />
  )
}

export default FeeTypeSingleSelection