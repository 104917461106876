import { ChangeEvent, useEffect, useRef, useState } from 'react';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import {
  EnumRepaymentMode,
  EnumRepaymentOrderType,
} from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Master';

import {
  DialogInOne,
  MpTextField,
  MpTextFieldNumberOnly,
  SingleSelection,
} from '../../../components';
import TimePicker from '../../../components/DatePicker';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { Box, Checkbox, SelectChangeEvent } from '../../../components/MuiGenerals';
import MpTextFieldWithEndAdornment from '../../../components/TextField/MpTextFieldWithEndAdornment';
import { convertDateOrDatetimeToDbFormat } from '../../../helper';
import { useAlerting, useTranslation } from '../../../hooks';
import useExchangeRate from '../../../hooks/useExchangeRate';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useGetProgram } from '../../../hooks/useProgram';
import {
  amountDivideDecimals,
  bigNumStrMulitpleDecimals,
  calculateBigNumbers,
  compareBigNumbers,
  isEmptyObject,
  roundDownTruncate,
} from '../../../utils';
import { ASSET_BALANCE_DP } from '../../../utils/config';
import { EnumHours24HoursFormat } from '../../../utils/constant';
import { useZusDialogStore } from '../../../zustand/store';
import {
  apiObj as api,
  IrewardBalanceApiRes,
  translateKeyObj as TK,
  translatePrefix,
  useZusParams,
} from './config';

interface Ifield {
  repaymentMode: string;
  repaymentSubMode: string;
  programName: string;
  customerNumber: string;
  scheduleDate: Date | null;
  scheduleTime: string;
  creditRepaymentAmount: string;
  isImmediate: boolean;
  asset: string;
  assetRepaymentAmount: string;
  externalReference: string;
}

const initFields: Ifield = {
  repaymentMode: '',
  repaymentSubMode: '',
  programName: '',
  scheduleDate: null,
  scheduleTime: '',
  customerNumber: '',
  creditRepaymentAmount: '',
  isImmediate: false,
  asset: '',
  assetRepaymentAmount: '',
  externalReference: '',
};

type IrewardBalanceInfo = {
  enum: Record<string, string>;
  assetBalanceObj: Record<string, { balance: string; decimals: number }>;
};

const initRewardBalanceInfo = { enum: {}, assetBalanceObj: {} };

const DEFAULT_REDEEM_RELEASE_AMOUNT = '0.00';

export default function DialogCreateRequest() {
  const zusDialog = useZusDialogStore();

  const { t, te, tc } = useTranslation(translatePrefix);

  const zusParams = useZusParams();

  const [fields, setFields] = useState(initFields);

  const { alerting } = useAlerting();

  const { EnumProgramCurrency } = useGetProgram();

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const creditCurrency = EnumProgramCurrency[fields.programName];

  const isRedeemMode = Number(fields.repaymentMode) === EnumRepaymentMode.Reward;
  const isClassicMode = Number(fields.repaymentMode) === EnumRepaymentMode.Classic;
  const isFpsSubMode = Number(fields.repaymentSubMode) === EnumRepaymentOrderType.RepaymentOrderFPS;

  const selectableRepaymentMode = (() => {
    const { RepaymentOrderClassicGeneric, RepaymentOrderFPS, RepaymentOrder711 } =
      EnumRepaymentOrderType;

    return { RepaymentOrderClassicGeneric, RepaymentOrderFPS, RepaymentOrder711 };
  })();

  const onChange =
    (field: keyof typeof fields) =>
    (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
      setFields((fields) => ({ ...fields, [field]: e.target.value }));
    };

  const timeoutIDRef = useRef<NodeJS.Timeout>();

  const [rewardBalanceInfo, setRewardBalanceInfo] =
    useState<IrewardBalanceInfo>(initRewardBalanceInfo);

  const handleCustomerNumberChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    clearTimeout(timeoutIDRef.current);
    onChange('customerNumber')(e);

    const fetch = async () => {
      const customerNumber = e.target.value;
      setFields((f) => ({ ...f, programName: '', asset: '' }));

      if (!customerNumber) {
        return;
      }

      const res = await api.customerProgram({ customerNumber });
      if (!res) {
        return alerting('warning', tc('customer_number_not_found'));
      }
      setFields((f) => ({ ...f, programName: res, asset: '' }));

      const rewardBalance: IrewardBalanceApiRes[] = await api.rewardBalance({ customerNumber });

      if (!rewardBalance || !rewardBalance.length) {
        return;
      }

      const transformedRes = rewardBalance.reduce(
        (acc: IrewardBalanceInfo, { rewardCurrency, rewardBalance, rewardCurrencyDecimals }) => {
          const rawRewardBalance = amountDivideDecimals(rewardBalance, rewardCurrencyDecimals);

          const displayRewardBalance = roundDownTruncate(rawRewardBalance, ASSET_BALANCE_DP);

          if (!Number(displayRewardBalance)) {
            return acc;
          }

          return {
            enum: { ...acc.enum, [rewardCurrency]: rewardCurrency },
            assetBalanceObj: {
              ...acc.assetBalanceObj,
              [rewardCurrency]: {
                balance: `${displayRewardBalance} ${rewardCurrency}`,
                decimals: rewardCurrencyDecimals,
              },
            },
          };
        },
        initRewardBalanceInfo
      );

      setRewardBalanceInfo(transformedRes);
    };

    const timeoutID = setTimeout(() => fetch(), 300);
    timeoutIDRef.current = timeoutID;
  };

  const handleAssetChange = (e: SelectChangeEvent<string>) => {
    onChange('asset')(e);
    setFields((prev) => ({ ...prev, assetRepaymentAmount: '' }));
  };

  const handleAssetRepaymentAmountChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    onChange('assetRepaymentAmount')(e);
    setFields((prev) => {
      const releaseCreditAmountBeforeRound = calculateBigNumbers(
        'multiply',
        prev.assetRepaymentAmount || 0,
        exchangeRate || 1
      );

      const creditRepaymentAmount = releaseCreditAmountBeforeRound
        ? roundDownTruncate(releaseCreditAmountBeforeRound.toFixed(), 2, {
            fillDecimals: true,
          })
        : DEFAULT_REDEEM_RELEASE_AMOUNT;

      return { ...prev, creditRepaymentAmount };
    });
  };

  useEffect(() => setFields((f) => ({ ...f, scheduleTime: '' })), [fields.scheduleDate]);

  const { exchangeRate } = useExchangeRate({
    programName: fields.programName,
    fromCurrency: fields.asset,
    toCurrency: creditCurrency,
  });

  const current = new Date();

  const disabledItemList = Object.entries(EnumHours24HoursFormat)
    .filter(([_name, value]) => {
      const scheduleDateStr = fields.scheduleDate?.toDateString();
      const validateDate = new Date(`${scheduleDateStr} ${value}`);
      return validateDate.getTime() < current.getTime();
    })
    .map(([name]) => name);

  const handleRepaymentModeChange = (e: SelectChangeEvent<string>) => {
    onChange('repaymentMode')(e);

    setFields((prev) => ({
      ...prev,
      asset: '',
      assetRepaymentAmount: '',
      creditRepaymentAmount:
        Number(e.target.value) === EnumRepaymentMode.Reward ? DEFAULT_REDEEM_RELEASE_AMOUNT : '',
    }));
  };
  const handleRepaymentSubModeChange = (e: SelectChangeEvent<string>) => {
    onChange('repaymentSubMode')(e);

    setFields((prev) => ({
      ...prev,
      externalReference: '',
    }));
  };

  const lastFieldName = isRedeemMode ? TK.estimatedReleaseCreditAmount : TK.creditRepaymentAmount;

  const labelElePairArr = [
    [
      TK.repaymentMode,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.repaymentMode) })}
        value={fields.repaymentMode}
        onChange={handleRepaymentModeChange}
        enumData={{ Classic: EnumRepaymentMode.Classic, Redeem: EnumRepaymentMode.Reward }}
        clearSelect={() => {}}
      />,
    ],
    [
      TK.repaymentSubMode,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.repaymentSubMode) })}
        value={fields.repaymentSubMode}
        onChange={handleRepaymentSubModeChange}
        enumData={selectableRepaymentMode}
        clearSelect={() => {}}
      />,
    ],
    [
      TK.scheduleTime,
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <TimePicker
          label={t('date')}
          type={'date'}
          timeValue={fields.scheduleDate}
          minEndTime={current}
          setTimeValue={(value: Date | null) =>
            setFields((fields) => ({ ...fields, scheduleDate: value }))
          }
          disabled={fields.isImmediate}
          sx={{ marginRight: '8px', width: 'calc((100% - 92.047px) / 2 + 8px)' }}
        />

        <SingleSelection
          disabled={fields.isImmediate || !fields.scheduleDate}
          label={t('time')}
          value={fields.scheduleTime}
          onChange={onChange('scheduleTime')}
          enumData={EnumHours24HoursFormat}
          nameFn={(name) => name}
          clearSelect={() => {
            setFields((f) => ({ ...f, scheduleTime: '' }));
          }}
          sx={{
            formControl: { marginRight: '8px', width: 'calc((100% - 92.047px) / 2 + 8px)' },
            select: { width: '100%' },
          }}
          className=""
          disabledItemList={disabledItemList}
        />
        <Box sx={{ margin: 0, display: 'flex', alignItems: 'end' }}>
          <FormControlLabel
            label={t(TK.immediate)}
            control={
              <Checkbox
                value={fields.isImmediate}
                onChange={(e) => {
                  setFields((f) => ({ ...f, isImmediate: e.target.checked }));
                  if (e.target.checked) {
                    setFields((f) => ({ ...f, scheduleDate: null, scheduleTime: '' }));
                  }
                }}
              />
            }
            sx={{
              span: { padding: '2px', fontSize: '0.6875rem' },
              userSelect: 'none',
              width: '100%',
              height: 'fit-content',
              margin: 0,
              justifyContent: 'end',
            }}
          />
        </Box>
      </Box>,
    ],
    [
      TK.customerNumber,
      <Box sx={{ position: 'relative', '>div': { width: '100%' } }}>
        <MpTextField
          label={tc('phInputField', { fieldName: t(TK.customerNumber) })}
          value={fields.customerNumber}
          onChange={handleCustomerNumberChange}
        />
        {fields.programName && (
          <FormHelperText
            children={t('program_name_helper_text', { programName: fields.programName })}
            sx={{ position: 'absolute', bottom: -15, left: 0, zIndex: 99 }}
          />
        )}
      </Box>,
    ],
    [
      TK.asset,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.asset) })}
        value={fields.asset}
        onChange={handleAssetChange}
        enumData={rewardBalanceInfo.enum}
        onOpen={() => {
          if (isEmptyObject(rewardBalanceInfo.enum)) {
            alerting('warning', t(TK.noAvailableAsset));
          }
        }}
        clearSelect={() => {}}
        nameFn={(name) => name}
        disabled={!fields.programName}
      />,
    ],
    [
      TK.assetRepaymentAmount,
      <Box sx={{ position: 'relative', '>div': { width: '100%' } }}>
        <MpTextFieldNumberOnly
          label={tc('phInputField', { fieldName: t(TK.assetRepaymentAmount) })}
          value={fields.assetRepaymentAmount}
          onChange={handleAssetRepaymentAmountChange}
          disabled={!exchangeRate}
          decimalCount={2}
        />
        {fields.asset && (
          <FormHelperText
            children={t('available_balance_helper_text', {
              availableBalance: rewardBalanceInfo.assetBalanceObj[fields.asset].balance,
            })}
            sx={{ position: 'absolute', bottom: -15, left: 0, zIndex: 99 }}
          />
        )}
        {exchangeRate && (
          <FormHelperText
            children={t('exchange_rate_helper_text', { exchangeRate })}
            sx={{ position: 'absolute', bottom: -26, left: 0, zIndex: 99 }}
          />
        )}
      </Box>,
    ],

    [
      lastFieldName,
      <MpTextFieldWithEndAdornment
        mode={'number'}
        label={tc('phInputField', { fieldName: t(lastFieldName) })}
        value={fields.creditRepaymentAmount}
        onChange={onChange('creditRepaymentAmount')}
        endAdornmentNode={creditCurrency}
        disabled={!fields.programName || isRedeemMode}
        decimalCount={2}
      />,
    ],
    [
      TK.externalReference,
      <MpTextField
        label={tc('optional')}
        value={fields.externalReference}
        onChange={onChange('externalReference')}
      />,
    ],
  ].filter(
    ([fieldName]) =>
      (!fields.repaymentMode &&
        fieldName !== TK.asset &&
        fieldName !== TK.assetRepaymentAmount &&
        fieldName !== TK.repaymentSubMode &&
        fieldName !== TK.externalReference) ||
      (isClassicMode &&
        fieldName !== TK.asset &&
        fieldName !== TK.assetRepaymentAmount &&
        (isFpsSubMode || fieldName !== TK.externalReference)) ||
      (isRedeemMode && fieldName !== TK.repaymentSubMode && fieldName !== TK.externalReference)
  ) as Array<[string, JSX.Element]>;

  const dialogContent = (
    <Box sx={{ marginBottom: '15px' }}>
      <GridBox labelElePairArr={labelElePairArr} columnCount={1} />
    </Box>
  );

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const handleSubmit = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr, [
      {
        fieldName: TK.scheduleTime,
        specificLabel: t('date'),
        isSkipped: fields.isImmediate,
        customMessage: tc('phSelection', { fieldName: t('date') }),
      },
      {
        fieldName: TK.scheduleTime,
        specificLabel: t('time'),
        isSkipped: fields.isImmediate,
        customMessage: tc('phSelection', { fieldName: t('time') }),
      },
      {
        fieldName: TK.scheduleTime,
        specificLabel: t('immediate'),
        isSkipped: true,
      },
      {
        fieldName: TK.creditRepaymentAmount,
        notAllowZeroMode: 'below',
        customMessage: fields.programName ? undefined : tc('customer_number_not_found'),
        isNumberOnlyField: true,
      },
      { fieldName: TK.asset, isSkipped: !isRedeemMode },
      { fieldName: TK.assetRepaymentAmount, isSkipped: !isRedeemMode },
      { fieldName: TK.externalReference, isSkipped: true },
    ]);
    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const {
      customerNumber,
      creditRepaymentAmount,
      programName,
      isImmediate,
      asset: redeemCurrency,
      assetRepaymentAmount,
      repaymentSubMode,
      externalReference: externalRefNum,
    } = fields;

    const scheduleDateStr = fields.scheduleDate?.toDateString();
    const rawScheduleDate = new Date(`${scheduleDateStr} ${fields.scheduleTime}`);
    const scheduleDate = convertDateOrDatetimeToDbFormat(rawScheduleDate, 'datetime', {
      isTimezoneConvert: true,
    });

    let res;

    if (isRedeemMode) {
      const selectedAssetBalanceObj = rewardBalanceInfo.assetBalanceObj[redeemCurrency];
      const redeemCurrencyDecimals = selectedAssetBalanceObj.decimals;
      const availableBalance = selectedAssetBalanceObj.balance.split(' ')[0];

      const redeemAmount = bigNumStrMulitpleDecimals(assetRepaymentAmount, redeemCurrencyDecimals);

      const redeemRequestParams = {
        programName,
        customerNumber,
        redeemCurrency,
        redeemCurrencyDecimals,
        redeemAmount,
        scheduleDate: isImmediate ? null : scheduleDate,
      };

      if (compareBigNumbers('greater', fields.assetRepaymentAmount, availableBalance)) {
        return alerting('warning', t(TK.availableBalanceChecking));
      }
      res = await api.createRedeemRequest(redeemRequestParams);
    } else {
      const classicRequestParams = {
        programName,
        customerNumber,
        creditRepaymentAmount,
        creditCurrency,
        scheduleDate: isImmediate ? null : scheduleDate,
        orderType: Number(repaymentSubMode),
        externalRefNum,
      };

      res = await api.createClassicRequest(classicRequestParams);
    }

    if (!res) {
      return;
    }

    await handleCloseDialog();

    zusParams.refetch();
    alerting('success', tc('create_request_success'));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_request'),
    self: {
      open: zusDialog.match('createRequestDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'sm',
    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
