import React, { Dispatch, SetStateAction } from 'react';
import {
  Container,
  Row,
  RowContent,
  RowHeader,
} from '../../../../../features/common/dialogs/layout/contentLayout';
import { useTranslation } from '../../../../../hooks';
import ProgramAgentIdSingleSelection from '../../../../../features/common/filterTable/components/Filters/ProgramAgentIdSingleSelection';
import { ICreateRequestFields } from '.';
import PaRebateAdjustmentRequestTransactionTypeSingleSelection from '../../../../../features/common/filterTable/components/Filters/PaRebateAdjustmentRequestTransactionTypeSingleSelection';
import AmountWithHkdTextField from '../../../../../features/common/filterTable/components/Filters/AmountWithHkdTextField';
import RemarksTextField from '../../../../../features/tcsp/tcspAccountApplication/components/CreateApplicationDialog/components/RemarksTextField';
import PlusMinusSingleSelection from '../../../../../features/common/filterTable/components/Filters/PlusMinusSingleSelection';
import { Box } from '../../../../../components/MuiGenerals';
import { EnumPaTransType } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/DbModel/Reward';

interface IProps {
  fields: ICreateRequestFields;
  setFields: Dispatch<SetStateAction<ICreateRequestFields>>;
}

const Content = (props: IProps) => {
  const { fields, setFields } = props;

  const { t, translate } = useTranslation('paRebateAdjustmentRequest');

  return (
    <div>
      <Container>
        <Row>
          <RowHeader>{t('programAgentId')}</RowHeader>
          <RowContent>
            {
              <ProgramAgentIdSingleSelection
                value={props.fields.programAgentId}
                onChange={(e) => setFields({ ...fields, programAgentId: e.target.value })}
                onClear={() => setFields({ ...fields, programAgentId: '' })}
              />
            }
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('transactionType')}</RowHeader>
          <RowContent>
            {
              <PaRebateAdjustmentRequestTransactionTypeSingleSelection
                value={fields.transactionType}
                onChange={(e) =>
                  setFields({ ...fields, transactionType: e.target.value, amountPlusMinsSign: '-' })
                }
                onClear={() =>
                  setFields({ ...fields, transactionType: '', amountPlusMinsSign: '-' })
                }
              />
            }
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('amount')}</RowHeader>
          <RowContent>
            {
              <div style={{ display: 'flex' }}>
                <Box
                  sx={{
                    '.MuiFilledInput-input': { padding: '12px' },
                    maxWidth: '120px',
                    marginRight: '12px',
                  }}
                >
                  <PlusMinusSingleSelection
                    value={fields.amountPlusMinsSign}
                    onChange={(e) => setFields({ ...fields, amountPlusMinsSign: e.target.value })}
                    onClearSelect={() => {}}
                    isDisabled={Number(fields.transactionType) !== EnumPaTransType.Others}
                  />
                </Box>
                <AmountWithHkdTextField
                  value={fields.amount}
                  onChange={(e) => setFields({ ...fields, amount: e.target.value })}
                />
              </div>
            }
          </RowContent>
        </Row>
        <Row>
          <RowHeader>{t('remarks')}</RowHeader>
          <RowContent>
            {
              <RemarksTextField
                value={fields.remarks}
                onChange={(value: string) => setFields({ ...fields, remarks: value })}
              />
            }
          </RowContent>
        </Row>
      </Container>
    </div>
  );
};

export default Content;
