import { CobrandManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';

const translatePrefix: Iprefix = 'referralCode';
const exportFileName = 'Referral Code';

const PermissionKey = CobrandManagement.ReferralCode;

interface Ifields {
  programName: string[];
  programAgentId: string,
  distributorAgentId: string,
  referralCode: string;
  status: string;
  idvMethod: string;
};

const initFields: Ifields = {
  programName: [],
  programAgentId: '',
  distributorAgentId: '',
  referralCode: '',
  status: '',
  idvMethod: '',
};

interface IsearchParam extends IdateFilter {
  programNames: string[];
  referralCode: string;
  programAgentId: string;
  distributorAgentId: string;
  status: string;
  kycIdvMethod: string;
}

const translateKeyObj = {
  creationTime: 'creation_time',
  programName: 'program_name',
  referralCode: 'referral_code',
  questionType: 'question_type',
  status: 'status',
  idvMethod: 'idv_method',
  lastModifiedTime: 'last_modified_time',
  lastModifiedBy: 'last_modified_by',
  operation: 'operation',
  createdBy: 'created_by',
  createRecord: 'create_record',
  successEditRecord: 'success_edit_record',
  programAgentId: 'program_agent_id',
  distributorAgentId: 'distributor_agent_id',
} as const;

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  createRecord: (
    params: { referralCode: string; programName: string; kycIdvMethod: number; questionType: number; status: number },
    config?: any
  ) => Promise<any>;
  editRecord: (
    params: { referralCode: string; programName: string; kycIdvMethod: number; status: number },
    config?: any
  ) => Promise<any>;
} = {
  export: api.CobrandManagement.referralCode.getExport,
  table: api.CobrandManagement.referralCode.getAll,
  createRecord: api.CobrandManagement.referralCode.postCreateRecord,
  editRecord: api.CobrandManagement.referralCode.postEditRecord,
} as const;

interface ItableApiRes {
  id: number;
  merchantId: number;
  programAgentId: string;
  distributorAgentId: string;
  questionType: string;
  programName: string;
  referralCode: string;
  status: number;
  kycIdvMethod: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
}
export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
};
