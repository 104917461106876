export enum TableColumnKey {
  programAgentId = 'programAgentId',
  programName = 'programName',
  transactionType = 'transactionType',
  currency = 'currency',
  amount = 'amount',
  postBalance = 'postBalance',
  remarks = 'remarks',
  createdBy = 'createdBy',
  creationTime = 'creationTime',
  lastModifiedTime = 'lastModifiedTime',
}
