import qualifiedRepayment from './qualifiedRepayment';
import rewardBatch from './rewardBatch';
import rewardRecord from './rewardRecord';
import qualifiedRepaymentTransaction from './qualifiedRepaymentTransaction';
import statement from './statement';
import redeemHistory from './redeemHistory';
import rebateWalletBalance from './rebateWalletBalance';
import paRebateAdjustmentRequest from './paRebateAdjustmentRequest';

export default {
  qualifiedRepaymentTransaction,
  qualifiedRepayment,
  statement,
  rewardBatch,
  rewardRecord,
  redeemHistory,
  rebateWalletBalance,
  paRebateAdjustmentRequest,
};
