import { MpTextField } from '../../../../../components';
import MpTextFieldWithEndAdornment from '../../../../../components/TextField/MpTextFieldWithEndAdornment';
import { useTranslation } from '../../../../../hooks';

interface IProps {
  label?: string;
  value: any;
  onChange: (e: any) => void;
}

const AmountWithHkdTextField = (props: IProps) => {
  const { label, value, onChange } = props;
  const { tc } = useTranslation();

  return (
    <MpTextFieldWithEndAdornment
      mode={'number'}
      label={label || tc('phInputField', { fieldName: tc('amount') })}
      value={value}
      onChange={onChange}
      endAdornmentNode={'HKD'}
      decimalCount={2}
    />
  );
};

export default AmountWithHkdTextField;
