import { addDays, addHours } from 'date-fns';
import { ChangeEvent, useState } from 'react';

import { DialogInOne, MpTextFieldNumberOnly, SingleSelection } from '../../../components';
import TimePicker from '../../../components/DatePicker';
import { IdialogInOneProps } from '../../../components/DialogInOne';
import { GridBox } from '../../../components/Layout';
import { SelectChangeEvent } from '../../../components/MuiGenerals';
import { convertDateOrDatetimeToDbFormat } from '../../../helper';
import { useAlerting, useTranslation } from '../../../hooks';
import useAssets from '../../../hooks/useAssets/useAssets';
import useGridBoxInputValidation from '../../../hooks/useGridBoxInputValidation';
import { useGetProgram } from '../../../hooks/useProgram';
import { useZusDialogStore } from '../../../zustand/store';
import { apiObj as api, translateKeyObj as TK, translatePrefix, useZusParams } from './config';

const hoursOffSetFromGMT0 = new Date().getTimezoneOffset() / 60;

const tradeStartingDate = addHours(new Date(), hoursOffSetFromGMT0);

const dayAfterToday = addDays(tradeStartingDate, 1);

interface Ifields {
  tradeDate: Date | null;
  from: string;
  to: string;
  exchangeRate: string;
  exchangeRateUpperLimit: string;
  exchangeRateLowerLimit: string;
}

const initFields: Ifields = {
  tradeDate: dayAfterToday,
  from: '',
  to: 'HKD',
  exchangeRate: '',
  exchangeRateUpperLimit: '',
  exchangeRateLowerLimit: '',
};

export default function DialogCreateRecord() {
  const zusDialog = useZusDialogStore();

  const { t, tc } = useTranslation(translatePrefix);

  const zusParams = useZusParams();
  const { AllAssetCurrenciesEnum } = useAssets();
  const { EnumAllCurrencyList } = useGetProgram();

  const [fields, setFields] = useState(initFields);

  const { validateGridBoxInput } = useGridBoxInputValidation();

  const { alerting } = useAlerting();

  const onChange =
    (field: keyof typeof fields) =>
      (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>) => {
        setFields((f) => ({ ...f, [field]: e.target.value }));
      };

  const labelElePairArr: Array<[string, JSX.Element]> = [
    [
      TK.tradeDate,
      <TimePicker
        label={tc('phSelection', { fieldName: t(TK.tradeDate) })}
        type={'date'}
        minEndTime={tradeStartingDate}
        timeValue={fields.tradeDate}
        setTimeValue={(value: Date | null) =>
          setFields((fields) => ({ ...fields, tradeDate: value }))
        }
        helperText={t(TK.tradeDateHelperText, {
          date: convertDateOrDatetimeToDbFormat(fields.tradeDate, 'date'),
        })}
      />,
    ],
    [
      TK.from,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.from) })}
        value={fields.from}
        onChange={onChange('from')}
        enumData={AllAssetCurrenciesEnum}
        clearSelect={() => { }}
        nameFn={(name) => name}
      />,
    ],
    [
      TK.to,
      <SingleSelection
        label={tc('phSelection', { fieldName: t(TK.to) })}
        value={fields.to}
        onChange={onChange('to')}
        enumData={EnumAllCurrencyList}
        clearSelect={() => { }}
        nameFn={(name) => name}
      />,
    ],
    [
      TK.exchangeRate,
      <MpTextFieldNumberOnly
        label={tc('phInputField', { fieldName: t(TK.exchangeRate) })}
        value={fields.exchangeRate}
        onChange={onChange('exchangeRate')}
        decimalCount={6}
      />,
    ],
    // [
    //   TK.exchangeRateUpperLimit,
    //   <MpTextFieldNumberOnly
    //     label={tc('phInputField', { fieldName: t(TK.exchangeRateUpperLimit) })}
    //     value={fields.exchangeRateUpperLimit}
    //     onChange={onChange('exchangeRateUpperLimit')}
    //     decimalCount={2}
    //   />,
    // ],

    // [
    //   TK.exchangeRateLowerLimit,
    //   <MpTextFieldNumberOnly
    //     label={tc('phInputField', { fieldName: t(TK.exchangeRateLowerLimit) })}
    //     value={fields.exchangeRateLowerLimit}
    //     onChange={onChange('exchangeRateLowerLimit')}
    //     decimalCount={2}
    //   />,
    // ],
  ];

  const dialogContent = <GridBox labelElePairArr={labelElePairArr} columnCount={1} />;

  const handleCloseDialog = async () => {
    await zusDialog.close();
    setFields(initFields);
  };

  const handleSubmit = async () => {
    const invalidMessage = validateGridBoxInput(labelElePairArr, [
      // { label: tc('phInputField', { fieldName: t(TK.exchangeRateUpperLimit) }), isSkipped: true },
      // { label: tc('phInputField', { fieldName: t(TK.exchangeRateLowerLimit) }), isSkipped: true },
      { fieldName: TK.exchangeRate, notAllowZeroMode: 'below', isNumberOnlyField: true },
    ]);
    if (invalidMessage) {
      return alerting('warning', invalidMessage);
    }

    const { tradeDate: rawTradeDate, from: fromCurrency, to: toCurrency, exchangeRate } = fields;
    const tradeDate = convertDateOrDatetimeToDbFormat(rawTradeDate, 'date');

    const res = await api.createRecord({
      tradeDate,
      fromCurrency,
      toCurrency,
      exchangeRate,
    });

    if (!res) {
      return;
    }

    await handleCloseDialog();
    zusParams.refetch();
    alerting('success', tc('create_record_success'));
  };

  const dialogConfig: IdialogInOneProps = {
    title: tc('create_record'),
    self: {
      open: zusDialog.match('createRecordDialog'),
      onClose: handleCloseDialog,
    },
    content: dialogContent,
    onConfirm: handleSubmit,
    onCancel: handleCloseDialog,

    size: 'sm',
    dialogActionStyling: { padding: '8px' },
  };

  return <DialogInOne {...dialogConfig} />;
}
