import { MerchantPortal } from '@wallet-manager/pfh-pmp-node-def-types';
import axios from '../axiosInstance';

const getAll = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };
  return await axios.get(MerchantPortal.EptCobrandManagement.InjectionFeeConfig.getAll, input);
};

const exportTable = async (params: any, config?: any) => {
  const { pageSize, page, ...rest } = params;

  const input = {
    params: {
      ...rest,
      limit: pageSize,
      offset: pageSize * page,
    },
    ...config,
  };

  return await axios.get(MerchantPortal.EptCobrandManagement.InjectionFeeConfig.export, input);
};

const editRecord = async (param: any, config?: any) => {
  const input = {
    ...param,
    ...config
  }
  return await axios.post(MerchantPortal.EptCobrandManagement.InjectionFeeConfig.editRecord, input)
}

const createRecord = async (param: any, config?: any) => {
  const input = {
    ...param,
    ...config
  }

  return await axios.post(MerchantPortal.EptCobrandManagement.InjectionFeeConfig.createRecord, input)
}

export default { getAll, exportTable, editRecord, createRecord };