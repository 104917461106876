import { CustomerManagement } from '@wallet-manager/pfh-pmp-node-def-types/dist/src/FeatureCode/MerchantPortal';

import api from '../../../api';
import { IdateFilter, Ipage } from '../../../api/types';
import { Iprefix } from '../../../hooks/useTranslation';
import { initZusParams } from '../../../utils/config';
import { createZusInstance } from '../../../zustand/store';
import { IdetailsApiRes, IeditApiParams } from '../CustomerApplication/config';

const translatePrefix: Iprefix = 'customerList';
const exportFileName = 'Customer List';

const PermissionKey = CustomerManagement.CustomerList;

interface Ifields {
  customerId: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  customerStatus: string;
}

const initFields: Ifields = {
  customerId: '',
  email: '',
  phoneCountryCode: '',
  phoneNumber: '',
  customerStatus: '',
};

interface IsearchParam extends IdateFilter {
  customerId: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  status: string;
}

const tableTranslateKeyObj = {
  creationTime: 'creation_time',
  lastModifiedTime: 'last_modified_time',
  customerId: 'customer_id',
  phoneCountryCode: 'phone_country_code',
  phoneNumber: 'phone_no',
  customerStatus: 'customer_status',
  forcePasswordChange: 'force_password_change',
  latestVersion: 'latest_version',
  createdBy: 'created_by',
  operation: 'operation',
  updateInfo: 'update_info',
  updateEmailAddress: 'update_email_address',
  updatePhoneNo: 'update_phone_no',
} as const;

const dialogTranslateKeyObj = {
  programName: 'program_name',
  emailAddress: 'email_address',
  referralCode: 'referral_code',
  questiontype: 'question_type',
  details: 'details',
  personalDetails: 'personal_details',
  title: 'title',
  firstName: 'first_name',
  lastName: 'last_name',
  chineseName: 'chinese_name',
  alias: 'alias',
  gender: 'gender',
  dateOfBirth: 'date_of_birth',
  nationality: 'nationality',
  identificationDocumentType: 'identification_document_type',
  identificationDocumentTypeOthers: 'identification_document_type_others',
  identificationNumber: 'identification_number',
  dateOfExpiry: 'date_of_expiry',
  dateOfIssue: 'date_of_issue',
  usCitizenship: 'us_citizenship',
  maritalStatus: 'marital_status',
  educationalStatus: 'educational_status',
  educationalStatusOthers: 'educational_status_others',
  purposeForApplying: 'purpose_for_applying',
  otherPurpose: 'other_purpose',
  isAuthorizedByYou: 'is_authorized_by_you',

  occupationDetails: 'occupation_details',
  employmentStatus: 'employment_status',
  companyName: 'company_name',
  jobTitle: 'job_title',
  jobTitleOthers: 'job_title_others',
  businessNatureOrIndustry: 'business_nature_or_industry',
  otherIndustry: 'other_industry',
  employmentStartDate: 'employment_start_date',
  officeTelephoneNumber: 'office_telephone_number',
  monthlySalaryInHkd: 'monthly_salary_in_hkd',
  otherMonthlyIncome: 'other_monthly_income',

  residentialDetails: 'residential_details',
  residentialStatus: 'residential_status',
  otherStatus: 'other_status',
  residentialTelephoneNumber: 'residential_telephone_number',
  residentAddressSection: 'resident_address_section',
  deliveryAddressSection: 'delivery_address_section',
  addressLine1: 'address_line_1',
  addressLine2: 'address_line_2',
  addressLine3: 'address_line_3',
  postalCode: 'postal_code',
  city: 'city',
  country: 'country',
  residentialAddressLine1: 'residential_address_line_1',
  residentialAddressLine2: 'residential_address_line_2',
  residentialAddressLine3: 'residential_address_line_3',
  residentialPostalCode: 'residential_postal_code',
  residentialCity: 'residential_city',
  residentialCountry: 'residential_country',
  deliveryAddressLine1: 'delivery_address_line_1',
  deliveryAddressLine2: 'delivery_address_line_2',
  deliveryAddressLine3: 'delivery_address_line_3',
  deliveryPostalCode: 'delivery_postal_code',
  deliveryCity: 'delivery_city',
  deliveryCountry: 'delivery_country',
  remarks: 'remarks',
  requestApproved: 'request_approved',
  requestRejected: 'request_rejected',
  idvDetails: 'idv_details',
  manualVerifyIdv: 'manual_verify_idv',
  verify: 'verify',
  success: 'success',
  fail: 'fail',
  retry: 'retry',
  noPermission: 'no_permission',
  applicantId: 'applicant_id',
  workflowRunId: 'workflow_run_id',
  switchToManualReview: 'switch_to_manual_review',
  retryIdv: 'retry_idv',
  successRetryIdv: 'success_retry_idv',
  successManualReviewSwitch: 'success_manual_review_switch',
  successAssignCustomerNumber: 'success_assign_customer_number',
  successManualVerifyIdv: 'success_manual_verify_idv',
  failManualVerifyIdv: 'fail_manual_verify_idv',
  customerLevel: 'customer_level',
  placeOfIssue: 'place_of_issue',
  sameAddressCheckbox: 'same_address_checkbox',
  editSuccessfulMessage: 'edit_successful_message',
  originalPhoneNumber: 'original_phone_number',
  originalEmailAddress: 'original_email_address',
  newEmailAddress: 'new_email_address',
  newCountryCode: 'new_country_code',
  createdPendingApplicationSuccess: 'created_pending_application_success',
  newPhoneNumber: 'new_phone_number',
  createCustomer: 'create_customer',
  invalidEmailWarning: 'invalid_email_warning',
  createCustomerApplication: 'create_customer_application',
  createApplicationSuccessfulMessage: 'create_application_successful_message',
  createCustomerSuccessfulMessage: 'create_customer_successful_message',
} as const;

const translateKeyObj = { ...tableTranslateKeyObj, ...dialogTranslateKeyObj };

const omitKeyObj = Object.freeze({
  export: ['rawData'],
  table: [],
});

type ApiParam = Ipage & Partial<IsearchParam>;

const useZusParams = createZusInstance<ApiParam>(initZusParams);

const apiObj: {
  export: (params: ApiParam, config?: any) => Promise<any>;
  table: (params: ApiParam, config?: any) => Promise<any>;
  checkAllowEdit: (params: { customerId: string }, config?: any) => Promise<IoperationBtnsStatus>;
  applicationDetails: (params: { customerId: string }, config?: any) => Promise<IapplicationDetail>;
  updateInfo: (
    params: { applicationNumber: string } & IeditApiParams,
    config?: any
  ) => Promise<any>;
  createCustomer: (
    params: { email: string; phoneCountryCode: string; phoneNumber: string },
    config?: any
  ) => Promise<any>;
  createCustomerApplication: (
    params: {
      firstName: string;
      lastName: string;
      programName: string;
      customerId: string;
      idIssuedBy: string;
      idType: number;
      idNumber: string;
      dateOfBirth: string;
      referralCode: string;
      answers: Partial<IdetailsApiRes>;
    },
    config?: any
  ) => Promise<any>;
  updateEmailAddress: (params: { customerId: string; email: string }, config?: any) => Promise<any>;
  updatePhoneNo: (
    params: { customerId: string; phoneCountryCode: string; phoneNumber: string },
    config?: any
  ) => Promise<any>;
} = Object.freeze({
  export: api.CustomerManagement.customerList.getExport,
  table: api.CustomerManagement.customerList.getAll,
  checkAllowEdit: api.CustomerManagement.customerList.getAllowEdit,
  applicationDetails: api.CustomerManagement.customerList.getApplicationDetail,
  updateInfo: api.CustomerManagement.customerList.postUpdateInfo,
  createCustomer: api.CustomerManagement.customerList.postCreateCustomer,
  createCustomerApplication: api.CustomerManagement.customerList.postCreateCustomerApplication,
  updateEmailAddress: api.CustomerManagement.customerList.postUpdateCustomerEmail,
  updatePhoneNo: api.CustomerManagement.customerList.postUpdateCustomerPhone,
});

interface ItableApiRes {
  id: number;
  merchantId: number;
  customerId: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  latestVersion?: any;
  status: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  lastModifiedDate: string;
  passcode: string;
  forcePasswordChange: boolean;
}

interface IapplicationDetail {
  id: number;
  merchantId: number;
  applicationNumber: string;
  customerId: string;
  version: number;
  isLatestVersion: boolean;
  type: number;
  status: number;
  createdBy: string;
  createdDate: string;
  lastModifiedBy: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneCountryCode: string;
  phoneNumber: string;
  idIssuedBy: string;
  idType: number;
  dateOfBirth: string;
  programName: string;
  applicationReferralCode: any;
  questionType: string;
  maxCreditLimit: string;
  kycFacialStatus?: any;
  kycDocumentStatus?: any;
  kycWatchListStatus?: any;
  approvalsRequired: number;
  approvalsCount: number;
  kycIdvStatus: number;
  kycIdvMethod: number;
  idNumberEncrypted: string;
  cipher: string;
  lastModifiedDate: string;
  referralCode: string;
  customerNumber: string;
  idNumberHash: string;
  kycFacialSubStatus?: any;
  kycDocumentSubStatus?: any;
  kycWatchListSubStatus?: any;
  kycIdvManuallyVerifiedBy?: any;
  kycRef: KycRef;
  kycRemarks: string;
  creditLimit: string;
  customerLevel: string;
  answers: Answers;
  programAgentId: string;
  distributorAgentId: string;
}

interface Answers {
  alias: string;
  title: number;
  gender: string;
  industry: number;
  jobTitle: number;
  chineseName: string;
  companyName: string;
  nationality: string;
  deliveryCity: string;
  idTypeOthers: string;
  idDateOfIssue: string;
  maritalStatus: number;
  usCitizenship: boolean;
  educationLevel: number;
  idDateOfExpiry: string;
  industryOthers: string;
  jobTitleOthers: string;
  otherIncomeHKD: string;
  deliveryCountry: string;
  residentialCity: string;
  employmentStatus: number;
  monthlySalaryHKD: string;
  residentialStatus: number;
  deliveryPostalCode: string;
  purposeForApplying: number;
  residentialCountry: string;
  employmentStartDate: string;
  deliveryAddressLine1: string;
  deliveryAddressLine2: string;
  deliveryAddressLine3: string;
  educationLevelOthers: string;
  officeTelephoneNumber: string;
  residentialPostalCode: string;
  authorizedToThirdParty: boolean;
  residentialAddressLine1: string;
  residentialAddressLine2: string;
  residentialAddressLine3: string;
  residentialStatusOthers: string;
  purposeForApplyingOthers: string;
  sameAsResidentialAddress: boolean;
  residentialTelephoneNumber: string;
}

interface KycRef {
  applicantId?: any;
  workflowRunId?: any;
}

interface IoperationBtnsStatus {
  editInfo: boolean;
  createApplication: boolean;
}

export {
  initZusParams,
  translatePrefix,
  exportFileName,
  PermissionKey,
  type Ifields,
  initFields,
  type IsearchParam,
  useZusParams,
  translateKeyObj,
  omitKeyObj,
  apiObj,
  type ItableApiRes,
  type IapplicationDetail,
  type IoperationBtnsStatus,
};
