import useValidation from "../../../../hooks/useValidation";
import { ValidationConfigProps } from "../../../../hooks/useValidation/types";
import { requiredValidator } from "../../../../utils/validators/requiredValidator";
import { EditRecordFields } from "../types/EditRecordField";
import { TableColumnKey } from "../types/TableColumnKey";
import useLocalTranslate from "./useLocalTranslate";

interface IProps {
  fields: EditRecordFields;
}

const useDialogEditValidation = (props: IProps) => {
  const { fields } = props;

  const { t, tc } = useLocalTranslate();

  const validationConfig: ValidationConfigProps = [
    {
      key: TableColumnKey.FeeType,
      value: fields[TableColumnKey.FeeType],
      validators: [
        requiredValidator(
          tc('phSelection', { fieldName: t(TableColumnKey.FeeType) })
        )
      ]
    },
    {
      key: TableColumnKey.LowerBoundAmount,
      value: fields[TableColumnKey.LowerBoundAmount],
      validators: [
        requiredValidator(
          tc('phInputField', { fieldName: t(TableColumnKey.LowerBoundAmount) })
        )
      ]
    },
    {
      key: TableColumnKey.Fee,
      value: fields[TableColumnKey.Fee],
      validators: [
        requiredValidator(
          tc('phInputField', { fieldName: t(TableColumnKey.Fee) })
        )
      ]
    },
  ]

  return useValidation(validationConfig)

}

export default useDialogEditValidation;